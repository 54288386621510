import axiosClient from "./axiosClient";
import { AccountPagination } from "../general/types/index.d";
import { Account } from "../general/types/index.d";
import _ from "lodash";

const userApi = {
  // Get user list
  /**
   *
   * @param {AccountPagination} params
   */
  getUserList: (params) => {
    return axiosClient.post("/account/search", params);
  },

  // Get user info by id
  getUserInfo: (userId) => {
    const url = `/account/info`;
    let formData = new FormData();
    formData.append("userId", userId);
    return axiosClient.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },

  /**
   *
   * @param {Account&{avatarFile?:File, userId:number, password?:string}} params
   * @returns
   */
  updateUserInfo: (params) => {
    const url = `/account/edit`;

    let formData = new FormData();

    _.entries(params).forEach(([key, value]) => {
      formData.append(key, value);
    });

    return axiosClient.put(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },

  // Create new user
  /**
   *
   * @param {Account & {avatarFile?:File,password?:string}} user
   */
  createUser: (user) => {
    const url = `/account/create`;

    let formData = new FormData();

    _.entries(user).forEach(([key, value]) => {
      formData.append(key, value);
    });

    return axiosClient.post(url, formData, {
      headers: { "Content-Type": "multipart/form-data" },
    });
  },

  // Delete users
  /**
   *
   * @param {number[]} userIds
   */
  deleteUsers: (userIds) => {
    const url = `/account/delete`;

    return axiosClient.delete(url, { data: { itemIds: userIds } });
  },
};

export default userApi;
