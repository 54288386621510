import React, { useEffect, useRef, useState } from "react";
import AppPage from "components/AppPage";
import { useTranslation } from "react-i18next";
import NavBar from "components/NavBar";
import { AppImages } from "general/constants/AppResource";
import AppCard from "components/AppCard";
import { useDispatch, useSelector } from "react-redux";
import BaseSearchBar from "general/custom-fields/BaseSearchBar";
import TableListHistory from "pages/History/component/TableListHistory";
import SelectArea from "components/SelectArea/SelectArea";
import areaApi from "api/areaApi";
import { setAllAreas } from "../../../AreaManager/areaSlice";
import DateRangePicker from "react-bootstrap-daterangepicker";
import styles from "./HistoryHomeScreen.scss";
import classnames from "classnames/bind";
import { useResizeDetector } from "react-resize-detector";
import { findAreaById } from "general/utils/Utils";

const cx = classnames.bind(styles);
function HistoryHomeScreen(props) {
  // MARK: --- Params ---
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState("");
  const dispatch = useDispatch();
  const [dates, setDates] = useState(["--/--/----", "--/--/----"]);
  // Area
  const [areasByUser, setAreasByUser] = useState([]);
  const [currentArea, setCurrentArea] = useState();
  const currentUser = useSelector((state) => state.auth.current);
  const [selectedArea, setSelectedArea] = useState();
  const [currentAreaId, setCurrentAreaId] = useState();
  const { width, height, ref } = useResizeDetector();

  // MARK: --- Functions ---
  async function getAreas() {
    const res = await areaApi.getAreas();
    const { result, areas } = res.data;
    if (result == "success" && areas) {
      setAreasByUser(areas);
      dispatch(setAllAreas(areas));
    }
  }

  // MARK: ---Hooks ---
  const allAreas = useSelector((state) => state.area.allAreas);
  useEffect(() => {
    if (
      allAreas &&
      currentUser &&
      currentUser.areaId > 0 &&
      selectedArea?.AreaId != currentUser.areaId
    ) {
      setSelectedArea(
        findAreaById(currentUser.areaId ? currentUser.areaId : -1)
      );
    
    }
  }, [currentUser, allAreas]);
  useEffect(() => {
    getAreas();
  }, []);
  /// Area
  useEffect(() => {
    let areaId = selectedArea
      ? selectedArea.AreaId
      : currentUser
      ? currentUser.areaId
      : null;
    if (areaId) {
      setCurrentAreaId(areaId);
    }
  }, [selectedArea, currentUser.areaId]);
  useEffect(() => {
    if (!currentArea) {
      setCurrentArea(
        areasByUser.filter((area) => area.id == currentUser?.areaId).at(0)
      );
    }
  }, [areasByUser]);

  return (
    <AppPage
      backgroundImage={AppImages.providerBackground}
      headerTitle={t("appName")}
    >
      <NavBar navBarTitle={t("appName")}></NavBar>
      <div ref={ref} className="d-flex flex-column flex-column-fluid my-2">
        <AppCard
          title={t("UserLogs")}
          buttonText=""
          additionalHeader={
            <>
              <div className="col-12 col-md-3 max-w-md-300px px-smaller-md-0 pl-md-0">
                <BaseSearchBar
                  name="searchBar"
                  placeholder={t("Search")}
                  onSubmit={setSearchText}
                  className="w-100"
                />
              </div>
              <div className="d-flex flex-row align-items-center col-12 col-md-4 max-w-md-350px mt-4 mt-md-0 px-smaller-md-0">
                <p
                  className={`display-none font-weight-bold ${
                    width > 890 ? "display-md-block mr-3" : ""
                  }`}
                  style={{ color: "#4A5677" }}
                >
                  {t("Display")}
                </p>
                <SelectArea
                  onSelect={(area) => {
                    setSelectedArea(area);
                  }}
                  rootAreaId={currentUser?.areaId}
                  selectedArea={selectedArea}
                  className="flex-grow-1"
                />
              </div>

              <div className="d-flex flex-row align-items-center w-100 w-md-fit-content mt-4 mt-md-0 ml-md-4">
                <p
                  className={`display-none font-weight-bold mr-3 ${
                    width > 890 ? "display-md-block" : ""
                  }`}
                  style={{ color: "#4A5677" }}
                >
                  {t("ByTheTime")}
                </p>
                <DateRangePicker
                  onCallback={(start, end) => {
                    console.log(start, end);
                    if (
                      start.format("DD/MM/YYYY") != end.format("DD/MM/YYYY")
                    ) {
                      setDates([start, end]);
                    } else {
                      setDates([start, "--/--/----"]);
                    }
                  }}
                >
                  <button className="toggle-button position-relative">
                    <span
                      className="ml-2 mr-2"
                      style={{ color: "#4A5677", fontWeight: "600" }}
                    >
                      {t("from")}{" "}
                    </span>
                    <span style={{ color: "#3ACE5A" }}>
                      {dates[0] !== "--/--/----"
                        ? dates[0].format("DD/MM/YYYY")
                        : "--/--/----"}
                    </span>
                    <span
                      className="mr-2 ml-2"
                      style={{ color: "#4A5677", fontWeight: "600" }}
                    >
                      {t("to")}{" "}
                    </span>
                    <span className="mr-10" style={{ color: "#E64135" }}>
                      {dates[1] !== "--/--/----"
                        ? dates[1].format("DD/MM/YYYY")
                        : "--/--/----"}
                    </span>
                    <i
                      className="far fa-angle-down position-absolute top-50 translate-middle-y"
                      style={{ right: 12 }}
                    ></i>
                  </button>
                </DateRangePicker>
              </div>
            </>
          }
        >
          <TableListHistory
            searchText={searchText}
            selectedArea={selectedArea}
            dates={dates}
          />
        </AppCard>
      </div>
    </AppPage>
  );
}

export default HistoryHomeScreen;
