import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
// import { TokenService } from "../../services/storage.service";
import "./styles.scss";
import { useHistory } from "react-router-dom";
import FormikControl from "../inputs/FormikControl";
import { useTranslation } from "react-i18next";
import { thunkSignIn } from "../../authSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import PreferenceKeys from "general/constants/PreferenceKeys";
import Utils from "general/utils/Utils";
import authApi from "api/authApi";
import ToastHelper from "general/helpers/ToastHelper";
const ForgotPasswordForm = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const errorMsg = useSelector((state) => state?.error?.msg);
  const [errMsgAPI, setErrMsgAPI] = useState(null);
  const [isHiddenPassword, setIsHiddenPassword] = useState(true);
  const [nLogin, setNLogin] = useState(null);
  const [loading, setLoading] = useState(false);
  const [checkMail, setCheckMail] = useState(false);
  // const [disableButton, setDisableButton] = useState(false);
  const initialValues = {
    account: "",
  };


  useEffect(() => {
    document.getElementsByName("account")[0].focus();
  }, []);

  const validationSchema = Yup.object({
    account: Yup.string().required(t("enterAccount")),
  });

  const onSubmit = async (values, props) => {
    console.log("rspassword", values);
    setLoading(true);
    // debugger
    try {
      let res = await authApi.getResetPasswordLink(values);
      //   debugger
      if (res) {
        let data = res.data;
        setLoading(false);
        console.log(data);
        if (data.result === "success") {
          ToastHelper.showSuccess(
            "Yêu cầu thành công . Hãy kiểm tra mail của bạn"
          );
          setCheckMail(true);
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <div className="form-title mb-2">{t("forgotPassword")}</div>
              {errorMsg ? (
                <div className="login-form-error m-0">
                  {errorMsg.Description}
                </div>
              ) : null}
              <FormikControl
                control="input"
                type="text"
                label={
                  <p className="font-weight-normal font-size-sm line-height-sm mb-0">
                    {" "}
                    {t("userName")}{" "}
                  </p>
                }
                placeholder={t("userNamePlaceHolder")}
                name="account"
              />
              {checkMail ? (
                <p className="font-weight-bold font-size-sm line-height-lg mb-0" style={{color:"#3F434A"}}>
                  {" "}
                  {t("SendResetPasswordLink")}{" "}
                </p>
              ) : null}

              <div className="login-form-divider" />

              <button
                className="login-button d-flex justify-content-center"
                type="submit button"
                disabled={!formik.isValid || loading}
              >
                {loading === false ? null : (
                  <span className="spinner-border spinner-border-sm mr-2 mt-1"></span>
                )}
                <p className="font-weight-bold text-white mb-0">
                  {t("Submit")}
                </p>
              </button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ForgotPasswordForm;
