import React, { useEffect, useRef, useState } from "react";
import AppPage from "components/AppPage";
import { useTranslation } from "react-i18next";
import ModalCreateProvider from "./../../components/ModalCreateProvider";
import AppCard from "components/AppCard";
import TableListProviders from "pages/ProviderManager/components/TableListProviders";
import TableListAccounts from "pages/ProviderManager/components/TableListAccounts";
import { AppImages } from "general/constants/AppResource";
import NavBar from "components/NavBar";
import ModalCreateAccount from "pages/ProviderManager/components/ModalCreateAccount";
import { useDispatch, useSelector } from "react-redux";
import BaseSearchBar from "general/custom-fields/BaseSearchBar";
import DevicesTable from "components/DevicesTable";
import providerApi from "api/providerApi";
import {
  setSelectedAccount,
  setSelectedProvider,
} from "pages/ProviderManager/providerSlice";
import AppBreadcrumb from "components/AppBreadcrumb";
import SelectArea from "components/SelectArea/SelectArea";
import { findAreaById } from "general/utils/Utils";

ProviderManagerHomeScreen.propTypes = {};

function ProviderManagerHomeScreen(props) {
  // MARK: --- Params ---
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.current); 
  const [modalCreateProviderShowing, setModalCreateProviderShowing] =
    useState(false);
  const [modalCreateAccountShowing, setModalCreateAccountShowing] =
    useState(false);
  const { selectedProvider, selectedAccount } = useSelector(
    (state) => state.provider
  );
  const [searchText, setSearchText] = useState("");
  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [funcDeleteItems, setFuncDeleteItems] = useState(() => () => {});
  const devicesTableRef = useRef();
  const [numberOfSelectedDevices, setNumberOfSelectedDevices] = useState(null);

  // MARK: --- Functions ---
  function handleSetSelectedItems(items) {
    setSelectedItems(items);
  }

  function handleSetFuncDeleteItems(func) {
    setFuncDeleteItems((id) => func(id));
  }

  const handleClickDelete = () => {
    devicesTableRef.current.handleDeleteMultipleDevices();
  };

  const handleClickConfigure = () => {
    devicesTableRef.current.handleConfigureMultipleDevices();
  };

  // MARK: --- Hooks ---
  const allAreas = useSelector((state) => state.area.allAreas);
  useEffect(() => {
    if (
      allAreas &&
      currentUser &&
      currentUser.areaId > 0 &&
      selectedArea?.AreaId != currentUser.areaId
    ) {
      setSelectedArea(
        findAreaById(currentUser.areaId ? currentUser.areaId : -1)
      );
    
    }
  }, [currentUser, allAreas]);

  useEffect(() => {
    return () => {
      dispatch(setSelectedProvider(null));
      dispatch(setSelectedAccount(null));
    };
  }, []);

  return (
    <AppPage
      backgroundImage={AppImages.providerBackground}
      headerTitle={t("OtherProvider")}
    >
      <NavBar navBarTitle={t("OtherProvider")}></NavBar>
      <div className="d-flex flex-column flex-column-fluid my-2">
        <AppCard
          title={t("ListProvider")}
          buttonText={
            selectedProvider === null
              ? t("AddProvider")
              : !(selectedProvider === null) && selectedAccount === null
              ? t("AddAccount")
              : ""
          }
          clickAdd={() => {
            selectedProvider === null
              ? setModalCreateProviderShowing(true)
              : !(selectedProvider === null) && selectedAccount === null
              ? setModalCreateAccountShowing(true)
              : {};
          }}
          headerRight={
            <BaseSearchBar
              name="searchBar"
              placeholder={t("Search")}
              onSubmit={setSearchText}
              className={
                selectedProvider === null
                  ? "display-smaller-md-none w-md-200px w-xl-250px w-xxxl-300px mr-6"
                  : "display-none"
              }
            />
          }
          additionalHeader={
            <>
              <div className={`col-12 ${
                  selectedProvider === null
                    ? "display-bigger-md-none p-0"
                    : "col-md-3 max-w-md-300px px-smaller-md-0 pl-md-0"
                }`}
              >
                <BaseSearchBar
                  name="searchBar"
                  placeholder={t("Search")}
                  onSubmit={setSearchText}
                  className="w-100"
                />
              </div>
              {selectedProvider !== null && (
                <div className="d-flex flex-row align-items-center col-12 col-md-4 max-w-md-350px mt-4 mt-md-0 px-smaller-md-0 pr-md-0">
                  <p
                    className="display-none display-md-block font-weight-bold mr-3 w-md-max-content"
                    style={{ color: "#4A5677" }}
                  >
                    {t("Display")}
                  </p>
                  <SelectArea
                    onSelect={(area) => {
                      console.log("Selected area", area);
                      setSelectedArea(area);
                    }}
                    rootAreaId={1}
                    selectedArea={selectedArea}
                    className="flex-grow-1"
                  />
                </div>
              )}
              
              <div className="d-flex flex-row w-100">
                {(!!selectedItems.length || !!numberOfSelectedDevices) && (
                  <button
                    className={`btn btn-light-danger mr-2 d-flex flex-row align-items-center mt-6 ${selectedProvider === null ? "mt-md-0" : ""}`}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!!selectedItems.length) {
                        let params = selectedItems.map((item) => item.id);
                        funcDeleteItems(params);
                      } else {
                        handleClickDelete();
                      }
                    }}
                  >
                    {!(selectedProvider === null) &&
                    !(selectedAccount === null) ? (
                      <i className="fas fa-satellite-dish"></i>
                    ) : (
                      <i className="fas fa-user-tie"></i>
                    )}
                    <p
                      className="font-weight-bold text-capitalize m-0"
                      style={{ fontSize: 13 }}
                    >
                      {`${t("Delete")} ${
                        selectedItems.length || numberOfSelectedDevices
                      }`}
                    </p>
                  </button>
                )}
                {!!numberOfSelectedDevices && (
                  <button
                    className="btn btn-light-primary mr-2 d-flex flex-row align-items-center ml-2 mt-6"
                    onClick={(e) => {
                      e.preventDefault();
                      handleClickConfigure();
                    }}
                  >
                    <i className="fas fa-satellite-dish"></i>
                    <p
                      className="font-weight-bold text-capitalize m-0"
                      style={{ fontSize: 13 }}
                    >
                      {`${t("Cấu hình")} ${numberOfSelectedDevices}`}
                    </p>
                  </button>
                )}
              </div>
            </>
          }
        >
          {selectedProvider === null ? (
            <TableListProviders
              searchText={searchText}
              selectedItems={selectedItems}
              handleSetSelectedItems={handleSetSelectedItems}
              handleSetFuncDeleteItems={handleSetFuncDeleteItems}
            />
          ) : !(selectedProvider === null) && selectedAccount === null ? (
            <TableListAccounts
              searchText={searchText}
              selectedArea={selectedArea}
              selectedItems={selectedItems}
              handleSetSelectedItems={handleSetSelectedItems}
              handleSetFuncDeleteItems={handleSetFuncDeleteItems}
            />
          ) : (
            <>
              <AppBreadcrumb
                items={[
                  {
                    name: selectedProvider.name,
                    onClick: () => {
                      dispatch(setSelectedProvider(null));
                      dispatch(setSelectedAccount(null));
                    },
                  },
                  {
                    name: selectedAccount.account,
                    onClick: () => {
                      dispatch(setSelectedAccount(null));
                    },
                  },
                ]}
                text={`Danh sách thiết bị`}
              />
              <DevicesTable
                getDevicesApi={(params) =>
                  providerApi.getDevicesOfAccount(selectedAccount.id, params)
                }
                searchText={searchText}
                selectedArea={selectedArea}
                setNumberOfSelectedDevices={setNumberOfSelectedDevices}
                ref={devicesTableRef}
              />
            </>
          )}
        </AppCard>
      </div>

      <ModalCreateProvider
        show={modalCreateProviderShowing}
        onClose={() => setModalCreateProviderShowing(false)}
      />

      <ModalCreateAccount
        show={modalCreateAccountShowing}
        onClose={() => setModalCreateAccountShowing(false)}
      />
    </AppPage>
  );
}

export default ProviderManagerHomeScreen;
