import { param } from "jquery";
import axiosClient from "./axiosClient";

const stationApi = {
    getStations: () => {
        const url = `/stations`;
        return axiosClient.get(url);
    },
    getStationsByAreaId: (areaId) => {
        const url = `/stations/area/${areaId}`;
        return axiosClient.get(url);
    },
    getStationInfo: (stationId) => {
        const url = `/stations/${stationId}`;
        return axiosClient.get(url);
    },
    getStationDevices: (stationId, params) => {
        const url = `/stations/${stationId}/devices`;
        return axiosClient.get(url, {params});
    },

    updateStation: (params) => {
        const { id, name } = params
        const url = `/stations/update`;
        return axiosClient.post(url, {
            id: id,
            name: name,
        });
    },

    createStation: (params) => {
        const { areaId, name } = params
        const url = `/stations/create`;
        return axiosClient.put(url, {
            name: name,
            areaId: areaId,
        });
    },

    deleteStation: (id) => {
        const url = `/stations/delete`;
        return axiosClient.delete(url, {data: {ids: id}});
    }
}

export default stationApi;
