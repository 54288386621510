import { AppIcons } from "./AppResource";
const casual = require("casual-browserify");

const AppData = {
  /**
   * @type {{type:'item'|'section',text:String,icon:String,path:String,subMenu:{text:String,path:String}[]}[]}
   */
  sidebarMenuItems: [
    { type: "section", text: "Summary", icon: "fad fa-ellipsis-h" },
    {
      type: "item",
      text: "Dashboard",
      icon: "fad fa-chart-line",
      path: "/dashboard",
      headerTitle: "DashBoard",
    },
    { type: "section", text: "AreaManager", icon: "fad fa-ellipsis-h" },
    {
      type: "item",
      text: "RadioStationManager",
      icon: "fad fa-solar-system",
      path: "/radio-stations",
    },
    // { type: 'item', text: 'Member', icon: 'fad fa-users', path: '/member' },
    {
      type: "item",
      text: "OtherProvider",
      icon: "fad fa-solar-panel",
      path: "/providers",
    },
    {
      type: "item",
      text: "AreaManager",
      icon: "fad fa-chart-area",
      path: "/area-manager",
    },
    { type: "section", text: "DeviceManager", icon: "fad fa-ellipsis-h" },

    {
      type: "item",
      text: "DeviceList",
      icon: "fad fa-satellite-dish",
      path: "/device-manager",
      subMenu: [
        { text: "RadioDevice", path: "/device-manager/speaker" },
        { text: "BulletinBoard", path: "/device-manager/bulletin-board" },
      ],
    },
    {
      type: "item",
      text: "DeviceMap",
      icon: "fad fa-map-marked",
      path: "/device-map",
    },
    { type: "section", text: "Bulletin", icon: "fad fa-ellipsis-h" },
    {
      type: "item",
      text: "BulletinList",
      icon: "fad fa-list",
      path: "/bulletins",
      // subMenu: [
      //   { text: "RadioBulletin", path: "/bulletins/speaker" },
      //   { text: "Ebulletin", path: "/bulletins/bulletin-board" },
      // ],
    },
    {
      type: "item",
      text: "EditBulletin",
      icon: "fad fa-file-edit",
      path: "/bulletins/edit",
      subMenu: [
        { text: "RadioBulletin", path: "/bulletins/edit/speaker" },
        { text: "Ebulletin", path: "/bulletins/edit/bulletin-board" },
      ],
    },

    {
      type: "item",
      text: "BulletinSchedule",
      icon: "fad fa-calendar-day",
      path: "/bulletins/schedule",
      // subMenu: [
      //   { text: "RadioBulletin", path: "/bulletins/schedule/speaker" },
      //   { text: "Ebulletin", path: "/bulletins/schedule/bulletin-board" },
      // ],
    },
    { type: "section", text: "History", icon: "fad fa-ellipsis-h" },
    {
      type: "item",
      text: "BulletinHistory",
      icon: "fad fa-history",
      path: "/bulletins/history",
      subMenu: [
        { text: "RadioBulletin", path: "/bulletins/history/radio" },
        { text: "Ebulletin", path: "/bulletins/history/bulletin-board" },
      ],
    },
    ,
    { type: "section", text: "Admintration", icon: "fad fa-ellipsis-h" },
    {
      type: "item",
      text: "AccountManager",
      icon: "fad fa-user-edit",
      path: "/users/manager",
    },
    {
      type: "item",
      text: "GroupManager",
      icon: "fad fa-users",
      path: "/users/group",
    },
    {
      type: "item",
      text: "UserLogs",
      icon: "fad fa-user-clock",
      path: "/users/history",
    },
    {
      type: "item",
      text: "Category",
      icon: "fad fa-books-medical",
      path: "/category",
    },
    // {
    //     type: 'item', text: 'Stock', icon: 'fad fa-chart-area', path: '/',
    //     subMenu: [
    //         // { text: 'Stock Category', path: '/stockCategory' },
    //     ],
    // },
    { type: "section", text: "Other", icon: "fad fa-ellipsis-h" },
    {
      type: "item",
      text: "Report",
      icon: "fad fa-book-open",
      path: "/reports",
    },
    {
      type: "item",
      text: "Feedback",
      icon: "fad fa-reply",
      path: "/feedback",
    },
  ],

  languages: [
    {
      icon: AppIcons.icFlagUs,
      title: "English",
      code: "en",
    },
    {
      icon: AppIcons.icFlagVi,
      title: "Vietnamese",
      code: "vi",
    },
  ],

  // Pagination per page items
  perPageItems: [
    { value: 5 },
    { value: 10 },
    { value: 20 },
    { value: 30 },
    { value: 50 },
    { value: 100 },
  ],
};

export default AppData;
