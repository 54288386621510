import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Global from "general/Global";
import AppBreadcrumb from "components/AppBreadcrumb";
import CheckBox from "components/CheckBox";
import DataTable from "components/Table";
import AppPagination from "components/AppPagination";
import "./index.scss";
import areaApi from "api/areaApi";
import { useDispatch, useSelector } from "react-redux";
import {
  setAreas,
  setCurrentArea,
  setAllAreas,
  deleteArea,
  setSelectedItems,
} from "pages/AreaManager/areaSlice";
import Utils from "general/utils/Utils";
import ModalCreateArea from "../ModalCreateArea";
import useSort from "hooks/useSort";
import ModalDelete from "components/AppModal/ModalDelete";
import ToastHelper from "general/helpers/ToastHelper";

function AreaTable(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // MARK: --- Params ---
  const {
    searchText,
    selectedArea,
    setSelectedArea,
    selectedItems,
    handleSetSelectedItems,
    handleSetFuncDeleteItems,
  } = props;

  const [page, setPage] = useState(1);
  const [step, setStep] = useState(Global.gDefaultPagination);
  const [editableArea, setEditableArea] = useState();
  const [deletableArea, setDeletableArea] = useState();
  const [modalCreateAreaShowing, setModalCreateAreaShowing] = useState(false);
  const [modalDeleteShowing, setModalDeleteShowing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { allAreas, areas, currentArea } = useSelector((state) => state.area);

  const { directionIndicator, sortOnClick } = useSort(areas, (sortedArray) =>
    dispatch(setAreas(sortedArray))
  );

  const filteredAreas = useMemo(
    () => Utils.filter(areas, "name", searchText),
    [searchText, areas]
  );

  // MARK: --- Functions ---

  async function getAreasInAreaId(areaID) {
    setIsLoading(true);
    try {
      const res = await areaApi.getAreasInAreaId(areaID);
      const { result, areas } = res?.data;
      if (result == "success" && areas) {
        dispatch(setAreas(areas));
      }
    } catch (error) {}

    setIsLoading(false);
  }

  async function getAreas() {
    setIsLoading(true);
    debugger;
    try {
      const res = await areaApi.getAreas();
      const { result, areas } = res?.data;
      if (result == "success" && areas) {
        dispatch(setAllAreas(areas));
        dispatch(setCurrentArea(areas[0]));
      }
    } catch (error) {}

    setIsLoading(false);
  }

  async function handleDeleteArea(id) {
    setModalDeleteShowing(true);
    setDeletableArea(id);
  }

  function getBreadcrumb(currentArea) {
    if (!Utils.isObjectEmpty(currentArea)) {
      const onClick = () => dispatch(setCurrentArea(currentArea));

      var item = [
        { name: currentArea.name, onClick: onClick, id: currentArea.id },
      ];

      if (currentArea.level !== 0) {
        item = getBreadcrumb(
          allAreas.filter((area) => area.id === currentArea.parentId).at(0)
        )?.concat(item);
      }

      return item;
    }
  }

  // MARK: --- Hooks ---
  useEffect(() => {
    if (areas.length === 0) getAreas();
    handleSetFuncDeleteItems(() => (id) => handleDeleteArea(id));
    return () => {
      handleSetSelectedItems([]);
      dispatch(setAreas([]));
    };
  }, []);

  useEffect(() => {
    if (selectedArea !== null && selectedArea?.AreaId !== currentArea?.id)
      setSelectedArea(null);
    if (currentArea) getAreasInAreaId(currentArea.id);
  }, [currentArea]);

  useEffect(() => {
    if (selectedArea) {
      dispatch(
        setCurrentArea(allAreas.find((item) => item.id === selectedArea.AreaId))
      );
    }
  }, [selectedArea]);

  return (
    <>
      <AppBreadcrumb
        items={getBreadcrumb(currentArea)}
        text={`Danh sách khu vực (${areas?.length} khu vực)`}
      />
      <DataTable
        cols={[0, 30, 20, 20, 20, 10]}
        headItems={[
          // Area Name
          <CheckBox
            checked={
              filteredAreas.length === selectedItems.length &&
              filteredAreas.length !== 0
            }
            onCheck={() => handleSetSelectedItems(filteredAreas)}
            onUncheck={() => handleSetSelectedItems([])}
          />,
          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("name")}
          >
            <p>
              {t("AreaName")}
              {directionIndicator("name")}
            </p>
          </div>,
          t("NumberOfAccounts"),
          t("NumberOfDevices"),
          t("nRadio"),
          t("Manage"),
        ]}
        // row
        dataItems={filteredAreas
          ?.slice(step * (page - 1), step * page)
          .map((item, index) => {
            let isRowCheck = !!selectedItems?.find(
              (area) => area.id === item.id
            );
            return {
              isRowCheck: isRowCheck,
              rowClick: () => {},
              data: [
                {
                  comp: (
                    <CheckBox
                      checked={isRowCheck}
                      onCheck={() =>
                        selectedItems?.find((area) => area.id === item.id) !==
                          null &&
                        handleSetSelectedItems([...selectedItems, item])
                      }
                      onUncheck={() =>
                        handleSetSelectedItems(
                          selectedItems.filter((area) => area.id !== item.id)
                        )
                      }
                    />
                  ),
                },
                {
                  comp: (
                    <div
                      className="d-flex flex-row align-items-center position-relative"
                      style={{ minWidth: 150 }}
                    >
                      <i className="fas fa-folder text-primary"></i>
                      <div
                        className="Bold_13 text-primary position-absolute w-100 top-50 translate-middle-y overflow-wrap pr-6"
                        style={{ left: 22 }}
                      >
                        {item.name}
                      </div>
                    </div>
                  ),
                  onClick: () => {
                    item.level < 2 && dispatch(setCurrentArea(item));
                  },
                },
                {
                  comp: (
                    <div
                      className="d-flex justify-content-center"
                      style={{ width: 95 }}
                    >
                      <p className="Regular_13">{item.userCount || 0}</p>
                    </div>
                  ),
                  onClick: () => {
                    item.level < 2 && dispatch(setCurrentArea(item));
                  },
                },
                {
                  comp: (
                    <div
                      className="d-flex justify-content-center"
                      style={{ width: 75 }}
                    >
                      <p className="Regular_13">{item.deviceCount || 0}</p>
                    </div>
                  ),
                  onClick: () => {
                    item.level < 2 && dispatch(setCurrentArea(item));
                  },
                },
                {
                  comp: (
                    <div
                      className="d-flex justify-content-center"
                      style={{ width: 130 }}
                    >
                      <p className="Regular_13">{item.stationCount || 0}</p>
                    </div>
                  ),
                  onClick: () => {
                    item.level < 2 && dispatch(setCurrentArea(item));
                  },
                },
                {
                  comp: (
                    <div style={{ width: 80 }}>
                      <i
                        className="fa fa-cog cursor-pointer mr-5 text-hover-primary"
                        onClick={() => {
                          setEditableArea(item);
                          setModalCreateAreaShowing(true);
                        }}
                      ></i>
                      <i
                        className="fa fa-trash-alt cursor-pointer mr-5 text-hover-danger"
                        onClick={() => {
                          handleDeleteArea(item);
                        }}
                      ></i>
                    </div>
                  ),
                },
              ],
            };
          })}
        step={step}
        onRefresh={() => {
          if (selectedArea) {
            getAreasInAreaId(selectedArea.AreaId);
            dispatch(
              setCurrentArea(
                allAreas.find((item) => item.id === selectedArea.AreaId)
              )
            );
          } else getAreas();
        }}
        isLoading={isLoading}
      />

      <ModalCreateArea
        show={modalCreateAreaShowing}
        onClose={() => setModalCreateAreaShowing(false)}
        editableArea={editableArea}
      />

      <ModalDelete
        show={modalDeleteShowing}
        title={t("DeleteArea")}
        text={
          Array.isArray(deletableArea)
            ? `Bạn có chắc muốn xóa ${deletableArea.length} khu vực không?`
            : `Bạn có chắc muốn xóa khu vực ${deletableArea?.name} không?`
        }
        onClose={() => setModalDeleteShowing(false)}
        onSubmit={async () => {
          const res = await areaApi.deleteArea(
            Array.isArray(deletableArea) ? deletableArea : deletableArea.id
          );
          const { result } = res.data;

          if (result === "success") {
            ToastHelper.showSuccess(t("DeletedAreaSuccessfully"));
            dispatch(
              deleteArea(
                Array.isArray(deletableArea) ? deletableArea : deletableArea.id
              )
            );
            handleSetSelectedItems([]);
          }
        }}
      />

      <AppPagination
        className="bg-white"
        total={filteredAreas.length}
        step={Global.gAvailablePagination}
        curStep={step}
        curPage={page}
        setPage={setPage}
        setStep={setStep}
      />
    </>
  );
}

export default AreaTable;
