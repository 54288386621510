import React, { Component } from "react";
import classes from "./SelectRadioStation.module.scss";
import PropTypes from "prop-types";

class SelectRadioStationItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expand: this.props.station?.state?.opened || false,
    };

    this.toggleChild = this.toggleChild.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  toggleChild() {
    this.props.setDropdownHeight();
    this.setState({
      expand: !this.state.expand,
    });
  }

  onSelect(id) {
    if (id && Number(id)) {
      this.props.onSelect(id);
    } else {
      this.props.onSelect(this.props.station.id);
    }
  }

  // static getDerivedStateFromProps(props, state) {
  //     debugger;
  //     if (props.station?.state?.opened !== state.expand) {
  //         return {
  //             expand: props.station?.state?.opened || false,
  //         }
  //     }
  //     return null;
  // }

  render() {
    let childStation = Array.isArray(this.props.station?.children)
      ? this.props.station.children.filter(
          (station) => !this.props.exceptIds.includes(station.id)
        )
      : [];
    return (
      <div className={`${classes.tree_item_container}`}>
        <div className={classes.tree_item}>
          {childStation.length ? (
            this.state.expand ? (
              <i
                className={classes.toggle_expand + " fal fa-minus-square mr-2"}
                onClick={this.toggleChild}
              />
            ) : (
              <i
                className={classes.toggle_expand + " fal fa-plus-square mr-2"}
                onClick={this.toggleChild}
              />
            )
          ) : (
            <i className="fal fa-plus-square mr-2 invisible" />
          )}
          <div
            className="d-flex align-items-center cursor-pointer"
            onClick={this.onSelect}
          >
            <i className="fal fa-folder-open text-primary mr-2" />
            <div className="flex-grow-1">{this.props.station.name}</div>
          </div>
        </div>
        <div className={this.state.expand ? "" : "d-none"}>
          {childStation.length && this.state.expand
            ? childStation.map((child) => {
                return (
                  <SelectRadioStationItem
                    station={child}
                    key={child.id}
                    onSelect={this.onSelect}
                    exceptIds={this.props.exceptIds}
                    setDropdownHeight={this.props.setDropdownHeight}
                  />
                );
              })
            : ""}
        </div>
      </div>
    );
  }
}

SelectRadioStationItem.propTypes = {
  onSelect: PropTypes.func.isRequired,
  setDropdownHeight: PropTypes.func.isRequired,
  station: PropTypes.object,
  exceptIds: PropTypes.array,
};

SelectRadioStationItem.defaultProps = {
  exceptIds: [],
};

export default SelectRadioStationItem;
