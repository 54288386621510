import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import AppPage from "components/AppPage";
import BaseSearchBar from "general/custom-fields/BaseSearchBar";
import SelectArea from "components/SelectArea/SelectArea";
import AppCard from "components/AppCard";
import NavBar from "components/NavBar";
import { AppImages } from "general/constants/AppResource";
import { useDispatch, useSelector } from "react-redux";
import BingMap from "pages/DeviceMap/components/BingMap";
import { thunkGetAreas } from "pages/AreaManager/areaSlice";
import BaseSearchBarSuggestion from "general/custom-fields/BaseSearchBarSuggestion";
import areaApi from "api/areaApi";
import Utils, { findAreaById } from "general/utils/Utils";
import bulletinBoardApi from "api/bulletinBoardApi";
import SelectProviderDropdown from "pages/ProviderManager/components/SelectProviderDropdown";
import { useResizeDetector } from "react-resize-detector";
import { SelectSort } from "pages/Category/components/SelectSort";

DeviceMapScreen.propTypes = {};

DeviceMapScreen.defaultProps = {};

const MAX_LONGITUDE = 109.4;
const MIN_LONGITUDE = 102.1;
const MAX_LATITUDE = 23.39;
const MIN_LATITUDE = 8.59;

let allDevices = [];
let allSpeakers = [];
let allBulletinBoards = [];

function DeviceMapScreen(props) {
  // MARK: --- Params ---
  const {} = props;
  const { t } = useTranslation();
  const currentUser = useSelector((state) => state.auth.current);
  const dispatch = useDispatch();
  const [selectedArea, setSelectedArea] = useState();
  const [selectedSpeaker, setSelectedSpeaker] = useState();
  const [devices, setDevices] = useState([]);
  const bingMapsRef = useRef(null);
  const deviceOption = [
    {value: "speaker", display: t("RadioDevice")},
    {value: "bulletinBoard", display: t("BulletinBoard")}
  ]
  const [currentDeviceOption, setCurrentDeviceOption] = useState("speaker");
  const { width, height, ref } = useResizeDetector();

  // MARK: --- Functions ---

  // MARK: --- Hooks ---
  const allAreas = useSelector((state) => state.area.allAreas);
  useEffect(() => {
    if (
      allAreas &&
      currentUser &&
      currentUser.areaId > 0 &&
      selectedArea?.AreaId != currentUser.areaId
    ) {
      setSelectedArea(
        findAreaById(currentUser.areaId ? currentUser.areaId : -1)
      );
    
    }
  }, [currentUser, allAreas]);
  
  useEffect(async () => {
    dispatch(thunkGetAreas());
    if (currentUser) {
      let res = await areaApi.getDevicesInArea(currentUser.areaId ?? 1, {
        limit: -1,
      });
      allSpeakers = res.data.devices
        .filter(
          (item) =>
            !Utils.isObjectEmpty(item.latitude) &&
            !Utils.isObjectEmpty(item.longitude) &&
            item.latitude > MIN_LATITUDE &&
            item.latitude < MAX_LATITUDE &&
            item.longitude > MIN_LONGITUDE &&
            item.longitude < MAX_LONGITUDE
        )
        .map((item) => ({ ...item, type: "speaker" }));

      res = await bulletinBoardApi.getBulletinBoard(
        currentUser.areaId ?? 1,
        {}
      );
      allBulletinBoards = res.data.bulletinBoards
        .filter(
          (item) =>
            !Utils.isObjectEmpty(item.latitude) &&
            !Utils.isObjectEmpty(item.longitude) &&
            item.latitude > MIN_LATITUDE &&
            item.latitude < MAX_LATITUDE &&
            item.longitude > MIN_LONGITUDE &&
            item.longitude < MAX_LONGITUDE
        )
        .map((item) => ({ ...item, type: "bulletinBoard" }));

      allDevices = [...allSpeakers, ...allBulletinBoards];
      setDevices(allDevices.filter(item => item.type == currentDeviceOption))
    }
  }, [currentUser]);

  useEffect(() => {
    let tempDevices = allDevices;
    if(selectedArea) {
      if (selectedArea?.AreaId == 1) {
        tempDevices = allDevices;
      } else {
        tempDevices = allDevices.filter((item) => item.areaId == selectedArea?.AreaId);
      }
    }

    if(currentDeviceOption) {
      tempDevices = tempDevices.filter(item => item.type == currentDeviceOption)
    }
    setDevices(tempDevices)
  }, [selectedArea, currentDeviceOption]);

  return (
    <>
      <AppPage
        backgroundImage={AppImages.imgBackground4}
        headerTitle={t("appName")}
      >
        <NavBar navBarTitle={t("appName")}></NavBar>
        <div ref={ref} className="d-flex flex-column flex-column-fluid my-2">
          <AppCard
            title={t("DeviceMap")}
            buttonText=""
            clickAdd={() => {
              setModalCreateAreaShowing(true);
            }}
            additionalHeader={
              <>
                <div className="col-12 col-md-3 max-w-md-300px px-smaller-md-0 pl-md-0">
                  <BaseSearchBarSuggestion
                    className="w-100"
                    placeholder="Tìm kiếm"
                    listItems={devices}
                    renderItem={(item) => `[${item.deviceId}] ${item.name}`}
                    filterFields={["deviceId", "name"]}
                    onSelect={(item) => {
                      if (item?.id == selectedSpeaker?.id)
                        bingMapsRef.current.zoomSelectedSpeaker();
                      setSelectedSpeaker(item);
                    }}
                  />
                </div>
                <div className="d-flex flex-row align-items-center col-12 col-md-4 max-w-md-350px mt-4 mt-md-0 px-smaller-md-0">
                  <p
                    className={`display-none ${width > 792 && "display-md-block"} font-weight-bold mr-3 w-md-max-content`}
                    style={{ color: "#4A5677" }}
                  >
                    {t("Display")}
                  </p>
                  <SelectArea
                    onSelect={(area) => {
                      if (area?.AreaId == selectedArea?.AreaId)
                        bingMapsRef.current.zoomSelectedArea();
                      setSelectedArea(area);
                    }}
                    rootAreaId={currentUser.areaId}
                    selectedArea={selectedArea}
                    className="flex-grow-1"
                  />
                </div>
                <div className="d-flex flex-row align-items-center col-12 col-md-4 max-w-md-350px mt-4 mt-md-0 px-smaller-md-0 pr-md-0">
                  <p
                    className={`display-none ${width > 792 && "display-md-block"} font-weight-bold mr-3 w-md-max-content`}
                    style={{ color: "#4A5677" }}
                  >
                    {t("Devices")}
                  </p>
                  <SelectSort
                    className="flex-grow-1"
                    selections={deviceOption}
                    disable={false}
                    currentValue={_.find(deviceOption, { value: currentDeviceOption })?.display ?? t("RadioDevice")}
                    onSelect={(item) => {
                      setCurrentDeviceOption(item.value);
                    }}
                  />
                </div>
              </>
            }
          >
            <BingMap
              ref={bingMapsRef}
              areaId={selectedArea?.AreaId}
              selectedSpeaker={selectedSpeaker}
              allDevices={devices}
            />
          </AppCard>
        </div>
      </AppPage>
    </>
  );
}

export default DeviceMapScreen;
