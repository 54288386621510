import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import stationApi from "../../api/stationApi";
import userApi from "../../api/userApi";
import Global from "../../general/Global";
import { Account, AccountPagination } from "../../general/types/index.d";
/**
 * @typedef Props
 * @property {boolean} loadingState
 * @property {Account[]} accounts
 * @property {AccountPagination} pagination
 */

/**
 *
 * @type {Props}
 */
const initialState = {
  loadingState: false,
  accounts: [],
  pagination: {
    filters: {
      areaId: undefined,
      state: undefined,
    },
    limit: Global.gDefaultPagination,
    page: 0,
    query: undefined,
    total: undefined,
    order: undefined,
  },
};

export const thunkSearchAccount = createAsyncThunk(
  "account/search",
  /**
   *
   * @param {AccountPagination} params
   */
  async (params) => {
    return (await userApi.getUserList(params)).data;
  }
);

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    /**
     * @param state
     * @param {{payload:AccountPagination}} param1
     */
    setPagination(state, { payload }) {
      _.entries(payload).forEach(([key, value]) => {
        state.pagination[key] = value;
      });
    },
  },
  extraReducers: {
    [thunkSearchAccount.pending]: (state) => {
      state.loadingState = true;
    },
    [thunkSearchAccount.rejected]: (state) => {
      state.loadingState = false;
    },
    [thunkSearchAccount.fulfilled]: (
      state,
      { payload: { result, total, count, page, accounts } }
    ) => {
      state.loadingState = false;
      if (result == "success") {
        state.pagination.total = total;
        state.pagination.page = page;
        state.accounts = accounts;
      }
    },
  },
});

export default accountSlice.reducer;

export const { setPagination } = accountSlice.actions;
