import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Utils from "general/utils/Utils";

import AppPage from "components/AppPage";
import AppCard from "components/AppCard";

import { AppImages } from "general/constants/AppResource";
import NavBar from "components/NavBar";
import { useParams } from "react-router-dom";
import DevicesInfo from "pages/DeviceDetail/components/DeviceInfo";
import { thunkGetAreas } from "pages/AreaManager/areaSlice";

DeviceConfiguredScreen.propTypes = {};

function DeviceConfiguredScreen(props) {
  const { t } = useTranslation();
  let { ids } = useParams();
  const dispatch = useDispatch();

  // MARK: --- Functions ---

  // MARK: --- Hooks ---
  useEffect(() => {
    dispatch(thunkGetAreas({}));
  }, []);

  return (
    <>
      <AppPage
        backgroundImage={AppImages.imgBackground4}
        headerTitle="Chi tiết thiết bị"
      >
        <NavBar navBarTitle="Chi tiết thiết bị"></NavBar>
        <div className="d-flex flex-column flex-column-fluid my-2">
          <AppCard title={t("DeviceConfiguration")}>
            <DevicesInfo configured={false} ids={ids.split(".")} />
          </AppCard>
        </div>
      </AppPage>
    </>
  );
}

export default DeviceConfiguredScreen;
