import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { AppIcons } from "general/constants/AppResource";
import ReactBingmaps from "./ReactBingmaps";
import SpeakerInfo from "./SpeakerInfo";
import Utils from "general/utils/Utils";
import deviceApi from "api/deviceApi";
import { useTranslation } from "react-i18next";
import ToastHelper from "general/helpers/ToastHelper";
import ModalDelete from "components/AppModal/ModalDelete";
import _ from "lodash";
import ReactDOMServer from "react-dom/server";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import bulletinBoardApi from "api/bulletinBoardApi";

const DEFAULT_CENTER = [21.122246912198296, 107.37230039238918]; // Quang Ninh
const DEFAULT_ZOOM = 9;
let devices = [];
let chosenDevice = null;
let infoBox = null;
let deviceType = null;

const BingMap = forwardRef((props, ref) => {
  // MARK: --- Params ---
  const { areaId, selectedSpeaker, allDevices } = props;
  const { t } = useTranslation();
  const { allAreas, devicesInArea } = useSelector((state) => state.area);
  const [center, setCenter] = useState(DEFAULT_CENTER);
  const [zoom, setZoom] = useState(DEFAULT_ZOOM);
  const [heightMap, setHeightMap] = useState(window.innerHeight - 150);
  const [allSpeakers, setAllSpeakers] = useState([]);
  const [isZoomLocation, setIsZoomLocation] = useState(false);
  const [modalStopShowing, setModalStopShowing] = useState(false);
  const [listPushpinTitles, setListPushpinTitles] = useState([]);
  const history = useHistory();

  // MARK: --- Functions ---
  function infoBoxHandler(e) {
    let obj;
    if (e.originalEvent.target.id === "infoBoxCloseButton") {
      e.target.setOptions({ visible: false });
    } else if (!Utils.isObjectEmpty(e.originalEvent.target.id)) {
      obj = devices.find(
        (devices) =>
          e.originalEvent.target.id == `DeviceId${devices.deviceId}` ||
          e.originalEvent.target.id == `StopDevice${devices.deviceId}` ||
          e.originalEvent.target.id == `ConfigDevice${devices.deviceId}`
      );
      if (e.originalEvent.target.id == `DeviceId${obj?.deviceId}`) {
        e.target.setOptions({ visible: false });
        if (!_.isEqual([obj.latitude, obj.longitude], center))
          setCenter([obj.latitude, obj.longitude]);
        if (!_.isEqual(18, zoom)) setZoom(18);
        setIsZoomLocation(true);
      } else if (
        e.originalEvent.target.id == `StopDevice${obj?.deviceId}` &&
        obj.state.toUpperCase() !== "IDLE"
      ) {
        handleStopDevice(obj.deviceId, obj.type);
        infoBox = e.target;
      } else if(e.originalEvent.target.id == `ConfigDevice${obj?.deviceId}`)
          obj.type == "speaker" 
          ? history.push(`/device-manager/${obj.deviceId}`)
          : history.push(`/device-manager/bulletin-board/${obj.deviceId}`);
    }
  }

  function handleSetDevice(devices) {
    setAllSpeakers(
      devices.map((item) => {
        return {
          location: [item.latitude, item.longitude],
          pushPinOption: {
            title: `${item.deviceId}`,
            icon:
              item.type == "speaker" ? 
              item.connection.toUpperCase() === "OFFLINE"
                ? AppIcons.icDisactiveSpeaker
                : AppIcons.icActiveSpeaker
              : item.connection.toUpperCase() === "OFFLINE" 
                ? AppIcons.icDisactiveBulletin
                : AppIcons.icActiveBulletin,
            anchor: [9, 9],
          },
          infoboxOption: {
            htmlContent: <SpeakerInfo speakers={[item]} />,
          },
          infoboxAddHandler: {
            type: "click",
            callback: (e) => infoBoxHandler(e),
          },
          connection: item.connection,
          type: item.type,
        };
      })
    );
  }

  function handleStopDevice(id, type) {
    chosenDevice = id;
    deviceType = type;
    setModalStopShowing(true);
  }

  function zoomSelectedArea() {
    if (areaId) {
      let obj = allAreas.find((item) => item.id == areaId);
      if (!Utils.isObjectEmpty(obj)) {
        handleSetDevice(devices);

        if (obj.latitude && obj.longitude) {
          if (!_.isEqual([obj.latitude, obj.longitude], center))
            setCenter([obj.latitude, obj.longitude]);
          if (areaId == 1) {
            if (zoom != 9) setZoom(9);
          } else {
            if (zoom != 11) setZoom(11);
          }
          setIsZoomLocation(true);
        }
      }
    }
  }

  function zoomSelectedSpeaker() {
    if (
      selectedSpeaker &&
      selectedSpeaker.latitude &&
      selectedSpeaker.longitude
    ) {
      if (
        !_.isEqual(
          [selectedSpeaker.latitude, selectedSpeaker.longitude],
          center
        )
      )
        setCenter([selectedSpeaker.latitude, selectedSpeaker.longitude]);
      if (zoom != 15) setZoom(15);
      setIsZoomLocation(true);
    }
  }
  
  function clusterMarker(type, size, color) {
    let svg = [];
    
    switch(type) {
      case "speaker":
        svg = [
          `<svg width="53" height="33" viewBox="0 0 53 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_7_12)">
              <g clip-path="url(#clip1_7_12)">
                <path d="M52.4775 14.6078C52.3945 12.2317 50.4533 9.34168 48.0687 9.17882L47.9028 9.16745V3.04529C47.9028 2.95313 47.9028 2.86096 47.9028 2.77006C47.8594 2.03291 47.5325 1.33868 46.9874 0.825426C46.4422 0.312177 45.7187 0.0175005 44.9606 0H44.8958C43.1725 0 41.8706 1.3017 41.8694 3.03014V3.61723L41.0537 4.16645L41.0356 4.17781C40.9322 4.24078 40.8365 4.3149 40.7503 4.39876C39.0399 6.08428 36.8486 7.11073 34.0594 7.59303C33.4188 7.70413 31.7902 8.00589 30.9551 8.03874C30.1201 8.07158 27.1351 8.03874 27.1351 8.03874C26.0388 8.04008 24.9879 8.46466 24.2127 9.21947C23.4375 9.97429 23.0014 10.9976 23 12.0651V17.3867C23.0014 18.4541 23.4375 19.4775 24.2127 20.2322C24.9879 20.9871 26.0388 21.4116 27.1351 21.413H31.1457C31.6929 21.4256 33.6354 21.7918 34.4004 21.9256C37.0029 22.4003 39.0685 23.4129 40.7152 25.0214C40.7851 25.0896 40.8601 25.1525 40.9396 25.2096L40.9837 25.2423L41.8706 25.9367C41.8706 26.1274 41.8706 26.3155 41.8706 26.51C41.8681 26.9807 41.982 27.4452 42.2028 27.864C42.4235 28.2828 42.7446 28.6438 43.1389 28.9162C43.5332 29.1887 43.9891 29.3646 44.468 29.4293C44.9469 29.4939 45.4348 29.4453 45.8903 29.2876C47.1365 28.8634 47.9105 27.7472 47.9105 26.3749V20.3146L48.1777 20.2754C50.4767 19.9345 52.5644 17.1809 52.4775 14.6078Z" fill="white"/>
                <path d="M31.5464 9.23037C32.4878 9.09655 33.3864 8.98539 34.2811 8.83768C37.1053 8.36676 39.6313 7.2999 41.6722 5.28864C41.7115 5.25802 41.7526 5.22977 41.7954 5.20405V24.2586C41.7391 24.2194 41.685 24.1772 41.6333 24.1323C39.6883 22.2385 37.3115 21.1742 34.6403 20.6868C33.708 20.5164 32.7627 20.4141 31.82 20.3017C31.5905 20.2739 31.5412 20.1856 31.5425 19.9836C31.5516 19.0493 31.5425 18.1137 31.5425 17.1794V9.23037H31.5464Z" fill="`, color,`"/>
                <path d="M43.166 14.7193C43.166 10.823 43.166 6.9263 43.166 3.0292C43.166 1.98759 43.9181 1.24394 44.9347 1.26162C45.3653 1.27341 45.7759 1.44163 46.0857 1.7332C46.3955 2.02476 46.582 2.41859 46.6087 2.83729C46.6087 2.92062 46.6087 3.00521 46.6087 3.0898C46.6087 10.852 46.6087 18.6142 46.6087 26.3765C46.6087 27.2211 46.1872 27.8486 45.4573 28.0973C45.1967 28.1881 44.9174 28.216 44.6433 28.1788C44.3692 28.1416 44.1084 28.0403 43.8832 27.8836C43.6581 27.7269 43.4752 27.5194 43.3504 27.2789C43.2254 27.0384 43.1622 26.7722 43.166 26.5027C43.1556 24.172 43.166 21.8401 43.166 19.5081C43.1651 17.9122 43.1651 16.316 43.166 14.7193Z" fill="`, color,`"/>
                <path d="M47.9819 19.0228V10.4374C49.4873 10.5409 51.1133 12.6861 51.1821 14.6518C51.2495 16.5835 49.6494 18.7779 47.9819 19.0228Z" fill="`, color,`"/>
                <path d="M27.1351 9.30095H30.1746V20.1501H27.1351C26.3823 20.1501 25.6604 19.859 25.128 19.3407C24.5957 18.8224 24.2967 18.1194 24.2967 17.3864V12.0647C24.2967 11.3317 24.5957 10.6288 25.128 10.1105C25.6604 9.59219 26.3823 9.30095 27.1351 9.30095Z" fill="`, color,`"/>
              </g>
              <rect x="`, size * 8, `" y="15" width="`, 40 - size * 8, `" height="18" rx="2" fill="white"/>
              <rect x="`, 1 + size * 8, `" y="16" width="`, 38 - size * 8, `" height="16" rx="1" fill="`, color, `"/>
            </g>
            <defs>
              <clipPath id="clip0_7_12">
                <rect width="53" height="33" fill="white"/>
              </clipPath>
              <clipPath id="clip1_7_12">
                <rect width="30" height="30" fill="white" transform="translate(23)"/>
              </clipPath>
            </defs>
          </svg>
          `,
        ];
        break;
      case "bulletinBoard":
        svg = [
          `<svg width="53" height="33" viewBox="0 0 53 33" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_105_4)">
          <path d="M29.8882 24.8649L29.8882 24.8649L31.2861 27.5H44.7262V27L44.7298 27.5C45.0482 27.4977 45.3634 27.4181 45.6406 27.2656C45.918 27.113 46.1509 26.8906 46.3049 26.6159C46.4595 26.3402 46.526 26.0266 46.4908 25.7121C46.4557 25.3981 46.322 25.1064 46.1146 24.8682C45.4919 24.153 44.811 23.3803 44.3346 22.8427L44.3065 22.811L44.2735 22.7845C44.0439 22.6001 43.7534 22.5081 43.4637 22.5133C41.8953 22.5067 39.5757 22.5061 37.3383 22.5056C35.5418 22.5052 33.7983 22.5048 32.5393 22.5013C32.3561 22.4929 32.1714 22.5255 32.002 22.5988C31.8328 22.672 31.6802 22.7852 31.5638 22.9338C31.138 23.4306 30.4963 24.1738 29.8882 24.8649Z" fill="`, color, `" stroke="white"/>
          <rect x="23" width="30" height="23" rx="2" fill="white"/>
          <rect x="24.5" y="1.5" width="27" height="20" rx="1" fill="`, color, `"/>
          <path d="M37.603 13.4048H37.0802C36.2095 13.4048 35.3389 13.4048 34.4671 13.4048C34.3849 13.4146 34.3012 13.4023 34.2271 13.3696C34.1529 13.3369 34.0917 13.2853 34.0515 13.2216C34.0114 13.1578 33.994 13.0849 34.0018 13.0123C34.0096 12.9398 34.0422 12.871 34.0953 12.815C35.6718 10.6171 37.2495 8.41857 38.8283 6.21935C38.9576 6.03949 39.1189 5.96915 39.302 6.01236C39.3549 6.02385 39.4046 6.04477 39.448 6.07384C39.4914 6.1029 39.5275 6.13949 39.5541 6.18132C39.5807 6.22316 39.5973 6.26935 39.6027 6.31703C39.6081 6.36471 39.6023 6.41286 39.5857 6.4585C39.3859 7.13575 39.1906 7.81266 38.9999 8.48924C38.8009 9.18457 38.6006 9.87991 38.397 10.5933H38.5457C39.5365 10.5933 40.5273 10.5933 41.5203 10.5933C41.7022 10.5933 41.8555 10.6295 41.9494 10.7772C42.0432 10.9249 41.9951 11.0586 41.899 11.1932C40.3271 13.3857 38.7562 15.5782 37.1866 17.7708C37.0516 17.9597 36.8914 18.031 36.7049 17.9878C36.652 17.9761 36.6023 17.9549 36.559 17.9258C36.5158 17.8966 36.4797 17.8599 36.4531 17.818C36.4266 17.7761 36.41 17.7299 36.4045 17.6822C36.399 17.6345 36.4047 17.5864 36.4212 17.5407C36.6225 16.8333 36.8246 16.1262 37.0275 15.4195C37.2152 14.7523 37.4062 14.0871 37.603 13.4048Z" fill="white"/>
          <rect x="`, size * 8, `" y="15" width="`, 40 - size * 8, `" height="18" rx="2" fill="white"/>
          <rect x="`, 1 + size * 8, `" y="16" width="`, 38 - size * 8, `" height="16" rx="1" fill="`, color, `"/>
          </g>
          <defs>
          <clipPath id="clip0_105_4">
          <rect width="53" height="33" fill="white"/>
          </clipPath>
          </defs>
          </svg>
          `
        ]
        break;
      default: break;;
    }
    return svg.join("");
  }

  // MARK: --- Hooks ---
  useEffect(() => {
    if (allDevices) {
      devices = allDevices;
      handleSetDevice(allDevices);
    }
  }, [allDevices]);

  useEffect(() => {
    zoomSelectedArea();
  }, [areaId]);

  useEffect(() => {
    zoomSelectedSpeaker();
  }, [selectedSpeaker]);

  useEffect(() => {
    if (isZoomLocation) setIsZoomLocation(false);
  }, [isZoomLocation]);

  useImperativeHandle(ref, () => ({
    zoomSelectedArea() {
      zoomSelectedArea();
    },
    zoomSelectedSpeaker() {
      zoomSelectedSpeaker();
    },
  }));

  useEffect(() => {
    const handleResizeMap = (e) => {
      setHeightMap(window.innerHeight - 150);
    };
    window.addEventListener("resize", handleResizeMap);

    return () => {
      window.removeEventListener("resize", handleResizeMap);
    };
  }, []);

  return (
    <>
      {allSpeakers && (
        <ReactBingmaps
          width={"100%"}
          height={heightMap < 300 ? 300 : heightMap}
          center={center}
          zoom={zoom}
          isZoomLocation={isZoomLocation}
          bingmapKey={process.env.REACT_APP_API_BINGMAPS}
          mapOptions={{
            allowInfoboxOverflow: true,
            showBreadcrumb: true,
          }}
          infoboxesWithPushPins={allSpeakers}
          setListPushpinTitles={(listTitles) =>
            setListPushpinTitles(listTitles)
          }
          clusterOption={{
            icon: (type, numberOfDigits) => {
              let size = 4 - numberOfDigits;

              switch (type) {
                case 0:
                  return clusterMarker("speaker", size, "#1772E0");
                case 1:
                  return clusterMarker("speaker", size, "#36C067");
                case 2:
                  return clusterMarker("speaker", size, "#F3312B");
                case 3:
                  return clusterMarker("bulletinBoard", size, "#1772E0");
                case 4:
                  return clusterMarker("bulletinBoard", size, "#36C067");
                case 5:
                  return clusterMarker("bulletinBoard", size, "#F3312B");
              }
            },
            htmlContent: (listTitles) => {
              let data = [];
              for (let i = 0; i < listTitles.length; i++) {
                data.push(
                  devices.find((item) => item.deviceId == listTitles[i])
                );
              }

              return <SpeakerInfo speakers={data} />;
            },
            clusterInfoboxAddHandler: {
              type: "click",
              callback: (e) => infoBoxHandler(e),
            },
          }}
        />
      )}

      <ModalDelete
        show={modalStopShowing}
        onClose={() => setModalStopShowing(false)}
        onSubmit={async () => {
          let res;
          if(deviceType == "speaker") {
            res = await deviceApi.stop({
            deviceIds: `["${chosenDevice}"]`,
            radioStationIds: "",
            areaId: "",
          });
          } else if(deviceType == "bulletinBoard") {
            res = await bulletinBoardApi.stopPlayingBulletins([chosenDevice]);
          }

          if (res.data.result === "success") {
            ToastHelper.showSuccess(`Đã dừng thiết bị ${chosenDevice}`);
            for (let i = 0; i < devices.length; i++)
              if (devices[i].deviceId == chosenDevice) {
                devices[i].state = "IDLE";
                break;
              }
            handleSetDevice(devices);
            let data = [];
            for (let i = 0; i < listPushpinTitles.length; i++) {
              data.push(
                devices.find((item) => item.deviceId == listPushpinTitles[i])
              );
            }
            infoBox.setHtmlContent(
              ReactDOMServer.renderToStaticMarkup(
                <SpeakerInfo speakers={data} />
              )
            );
          }

          setModalStopShowing(false);
        }}
        title={t("StopDevice")}
        text={`Bạn chắc chắn muốn dừng thiết bị: ${chosenDevice}`}
      />
    </>
  );
});

export default BingMap;
