export const BULLETIN_SOURCE_TYPES = [
  {type: "FILE", title: "BulletinSourceFile", icon: "file-music", "active": true},
  {type: "MIC", title: "BulletinSourceMic", icon: "microphone", "active": false},
  {type: "DEVICE", title: "BulletinSourceDevice", icon: "router", "active": false},
  {type: "DOCUMENT", title: "BulletinSourceDocument", icon: "comment-alt-edit", "active": false},
  {type: "RELAY", title: "BulletinSourceRelay", icon: "diagram-next", "active": false},
];

export const BULLETIN_BOARD_SOURCE_TYPES = [
  {type: "IMAGE", title: "Hình ảnh", icon: "image", "active": true},
  {type: "VIDEO", title: "Video", icon: "film", "active": false},
  {type: "TEXT", title: "Văn bản ", icon: "text", "active": false},
];

export const BULLETIN_PLAY_MODE = [
  {value: "FIX", display: "Cố định"},
  {value: "SCHEDULE", display: "Theo lịch"},
  {value: "NOW", display: "Phát ngay", default: true},
  {value: "EMERGENCY", display: "Khẩn cấp"},
];

export const BULLETIN_REPEAT_TYPE = [
  {value: "None", display: "Một lần", default: true, modes: ["NOW", "EMERGENCY"]},
  {value: "Day", display: "Hàng ngày", modes: ["FIX"]},
  {value: "Week", display: "Hàng tuần", modes: ["SCHEDULE"]},
  {value: "Month", display: "Hàng tháng", modes: ["SCHEDULE"]},
];

export const BULLETIN_PRIORITY = [
  {value: "NORMAL", display: "Thấp", default: true},
  {value: "MEDIUM", display: "Trung bình"},
  {value: "HIGH", display: "Cao"},
  {value: "EMERGENCY", display: "Khẩn cấp"},
];

export const BULLETIN_WEEK_DAYS = [
  {value: 2, title: "Thứ 2"},
  {value: 3, title: "Thứ 3"},
  {value: 4, title: "Thứ 4"},
  {value: 5, title: "Thứ 5"},
  {value: 6, title: "Thứ 6"},
  {value: 7, title: "Thứ 7"},
  {value: 1, title: "Chủ Nhật"},
];
