import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Loading from "components/Loading";
import './index.scss'
import { useResizeDetector } from "react-resize-detector";


AppCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  buttonText: PropTypes.string,
  clickAdd: PropTypes.func,
  headerRight: PropTypes.element,
  additionalHeader: PropTypes.element,
  isLoading: PropTypes.bool,
  backgroundColor: PropTypes.string
}

AppCard.defaultProps = {
  className: '',
  title: '',
  buttonText: '',
  clickAdd: null,
  isLoading: false,
  backgroundColor: 'white'
}


/**
 * - className (string)
 * - title (string)
 * - headerRight (element) : component after search and button
 * - buttonText (string) default: 'Add'. If empty wont show button
 * - additionalHeader (element): component below header
 * 
 */
function AppCard(props) {
  const { className, title, buttonText, clickAdd, isLoading,backgroundColor } = props
  const { t } = useTranslation();
  const { height, ref } = useResizeDetector();

  function onClick() {
    if (clickAdd) clickAdd()
  }

  return (
      <div className={`${className} d-flex flex-column shadow-sm bg-white rounded`}>
        {/* CARD HEADER */}
        <div className={`CardHeader d-flex flex-row flex-wrap justify-content-between align-items-center mx-6 mt-5 ${height > 0 ? "mb-3" : "mb-6"}`} style={{backgroundColor:backgroundColor, minHeight: 42}}>
          {/* TITLE */}
          <p className="CardTitle Bold_14 text-uppercase mr-2">{title}</p>

          {/* RIGHT SIDE BUTTON */}
          <div className="mt-5 mt-sm-0 d-flex flex-row align-items-center">
            <div className="CardToolkit d-flex flex-row flex-wrap align-items-center">
              {props.headerRight}
            </div>
            {
              buttonText?.length > 0 &&
              (<button className="CardButton d-flex flex-row align-items-center" onClick={onClick}>
                {buttonText == "Tải về báo cáo" ? <i className="fas fa-download"/> : <i className="far fa-plus-circle"/>}
                <p className='Bold_13 text-white text-capitalize m-0'>{t(buttonText)}</p>
              </button>)
            }
          </div>
        </div>

        {
          isLoading 
          ?
            <div className="d-flex align-items-center justify-content-center p-10">
              <Loading message={`${t("Loading")}...`} />
            </div>
          :
            <>
              {/* ADDITIONAL HEADER */}
              <div 
                ref={ref} 
                className={`AdditionalCardHeader d-flex flex-row flex-wrap align-items-center ${height > 0 ? "mx-6 mt-3 mb-6" : ""}`}
              >
                {props.additionalHeader}
              </div>
              {/* CONTENT */}
              <div className="Rows">
                {props.children}  
              </div>
            </>
        }
      </div>
  );
}

export default AppCard;
