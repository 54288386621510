import React from 'react';
import PropTypes from 'prop-types';


BaseSearchBar.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    onSubmit: PropTypes.func,
    noBorder: PropTypes.bool,
    defaultValue: PropTypes.string,
    width: PropTypes.number
};

BaseSearchBar.defaultProps = {
    type: 'text',
    placeholder: '',
    disabled: false,
    className: '',
    onSubmit: null,
    noBorder: false,
    width: -1
};


function BaseSearchBar(props) {
    // MARK: --- Params ---
    const {
        name,
        type,
        placeholder,
        disabled,
        className,
        onSubmit,
        noBorder,
        onFocus,
        defaultValue,
        width
    } = props;


    // MARK: --- Functions ---
    function handleTextChanged(e) {
        if (onSubmit) {
            onSubmit(e.target.value);
        }
    }

    return (
        <div className={`input-icon ${className} custom-search`} style={{width: width > 0 ? width : ""}}>
            <input
                id={name}
                onFocus={onFocus}
                type={type}
                className={`form-control ${noBorder && 'border-0'}`}
                placeholder={placeholder}
                onChange={handleTextChanged}
                autoComplete='none'
                defaultValue={defaultValue}
            />
            <span className=''>
                <i className="far fa-search text-muted" />
            </span>
        </div>
    );
}

export default BaseSearchBar;
