import React, { useState, useEffect, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Utils, { findAreaById } from "general/utils/Utils";
import { AppImages } from "general/constants/AppResource";
import AppPage from "components/AppPage";
import NavBar from "components/NavBar";
import AppCard from "components/AppCard";
import AppBreadcrumb from "components/AppBreadcrumb";
import BaseSearchBar from "general/custom-fields/BaseSearchBar";
import RadioStationTable from "pages/RadioStationManager/components/RadioStationTable";
import DevicesTable from "components/DevicesTable";
import ModalAddStation from "pages/RadioStationManager/components/ModalAddStation";
import stationApi from "api/stationApi";
import { thunkGetAreas } from "pages/AreaManager/areaSlice";
import SelectArea from "components/SelectArea/SelectArea";

function RadioStationManagerScreen(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // MARK: --- Params ---
  const currentUser = useSelector((state) => state.auth.current);
  const areasByUser = useSelector((state) => state.area.allAreas);

  const [currentArea, setCurrentArea] = useState();
  const [currentStation, setCurrentStation] = useState();
  const [selectedArea, setSelectedArea] = useState();

  const [modalAddStationShowing, setModalAddStationShowing] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [funcDeleteItems, setFuncDeleteItems] = useState(() => () => {});
  const devicesTableRef = useRef();
  const [numberOfSelectedDevices, setNumberOfSelectedDevices] = useState(null);

  const breadcrumbItems = useMemo(
    () => getBreadcrumb(currentArea),
    [currentArea]
  );
  // MARK: --- Functions ---
  // Recursive Func to get Breadcrumb
  function getBreadcrumb(currentArea) {
    if (!Utils.isObjectEmpty(currentArea)) {
      const onClick = () => {
        setCurrentAreaById(currentArea.id);
      };
      var item = [
        {
          name: currentArea.name,
          level: currentArea.level,
          onClick: onClick,
          id: currentArea.id,
        },
      ];

      if (currentArea.level != currentUser.level) {
        item = getBreadcrumb(
          areasByUser.filter((area) => area.id == currentArea.parentId).at(0)
        )?.concat(item);
      }

      return item;
    }
  }

  /**
   * Set current area by id. Change all areafilter and breadcrum
   * @param areaId
   * @param clearCurrentStation (bool) if true: show stations table instead of devices table. Default true
   */
  function setCurrentAreaById(areaId, clearCurrentStation = true) {
    if (clearCurrentStation) setCurrentStation(undefined);
    setCurrentArea(areasByUser.filter((area) => area.id == areaId).at(0));
    setSearchText("");
  }

  function handleSetSelectedItems(items) {
    setSelectedItems(items);
  }

  function handleSetFuncDeleteItems(func) {
    setFuncDeleteItems((id) => func(id));
  }

  const handleClickDelete = () => {
    devicesTableRef.current.handleDeleteMultipleDevices();
  };

  const handleClickConfigure = () => {
    devicesTableRef.current.handleConfigureMultipleDevices();
  };

  // MARK: --- Hooks ---
  const allAreas = useSelector((state) => state.area.allAreas);
  useEffect(() => {
    if (
      allAreas &&
      currentUser &&
      currentUser.areaId > 0 &&
      selectedArea?.AreaId != currentUser.areaId
    ) {
      setSelectedArea(
        findAreaById(currentUser.areaId ? currentUser.areaId : -1)
      );
    
    }
  }, [currentUser, allAreas]); 

  useEffect(() => {
    dispatch(thunkGetAreas({}));
  }, []);

  useEffect(() => {
    if (typeof currentArea == "undefined") {
      setCurrentArea(
        areasByUser.filter((area) => area.id == currentUser?.areaId).at(0)
      );
    }
  }, [areasByUser]);

  return (
    <>
      <AppPage
        backgroundImage={AppImages.radioStationBackground}
        headerTitle={t("ManageRadioStations")}
      >
        <NavBar navBarTitle={t("ManageRadioStations")}></NavBar>
        <div className="RadioStationManager d-flex flex-column flex-column-fluid my-2">
          <AppCard
            title={t("RadioStationList")}
            buttonText={t("AddStation")}
            clickAdd={() => setModalAddStationShowing(true)}
            additionalHeader={
              <>
                <div className="col-12 col-md-3 max-w-md-300px px-smaller-md-0 pl-md-0">
                  <BaseSearchBar
                    name="searchBar"
                    placeholder={t("Search")}
                    onSubmit={setSearchText}
                    className="w-100"
                  />
                </div>
                <div className="d-flex flex-row align-items-center col-12 col-md-4 max-w-md-350px mt-4 mt-md-0 px-smaller-md-0 pr-md-0">
                  <p
                    className="display-none display-md-block font-weight-bold mr-3 w-md-max-content"
                    style={{ color: "#4A5677" }}
                  >
                    {t("Display")}
                  </p>
                  <SelectArea
                    onSelect={(area) => {
                      setCurrentAreaById(area.AreaId);
                      setSelectedArea(area);
                    }}
                    rootAreaId={currentUser.areaId}
                    selectedArea={selectedArea}
                    className="flex-grow-1"
                  />
                </div>

                <div className="d-flex flex-row w-100">
                  {(!!selectedItems.length || !!numberOfSelectedDevices) && (
                    <button
                      className="btn btn-light-danger mr-2 d-flex flex-row align-items-center mt-6"
                      onClick={(e) => {
                        e.preventDefault();
                        if (!!selectedItems.length) {
                          let params = selectedItems.map((item) => item.id);
                          funcDeleteItems(params);
                        } else {
                          handleClickDelete();
                        }
                      }}
                    >
                      {currentStation ? (
                        <i className="fas fa-satellite-dish"></i>
                      ) : (
                        <i className="fas fa-folder"></i>
                      )}
                      <p
                        className="font-weight-bold text-capitalize m-0"
                        style={{ fontSize: 13 }}
                      >
                        {`${t("Delete")} ${
                          selectedItems.length || numberOfSelectedDevices
                        }`}
                      </p>
                    </button>
                  )}
                  {!!numberOfSelectedDevices && (
                    <button
                      className="btn btn-light-primary mr-2 d-flex flex-row align-items-center ml-2 mt-6"
                      onClick={(e) => {
                        e.preventDefault();
                        handleClickConfigure();
                      }}
                    >
                      <i className="fas fa-satellite-dish"></i>
                      <p
                        className="font-weight-bold text-capitalize m-0"
                        style={{ fontSize: 13 }}
                      >
                        {`${t("Cấu hình")} ${numberOfSelectedDevices}`}
                      </p>
                    </button>
                  )}
                </div>
              </>
            }
          >
            {currentStation ? (
              <>
                <AppBreadcrumb
                  items={breadcrumbItems}
                  text={`${currentStation.name}`}
                />
                <DevicesTable
                  getDevicesApi={(params) =>
                    stationApi.getStationDevices(currentStation.id, params)
                  }
                  searchText={searchText}
                  selectedArea={selectedArea}
                  setNumberOfSelectedDevices={setNumberOfSelectedDevices}
                  ref={devicesTableRef}
                />
              </>
            ) : (
              <RadioStationTable
                currentArea={currentArea}
                breadcrumbItems={breadcrumbItems}
                setCurrentStation={setCurrentStation}
                setCurrentAreaById={setCurrentAreaById}
                searchText={searchText}
                selectedItems={selectedItems}
                handleSetSelectedItems={handleSetSelectedItems}
                handleSetFuncDeleteItems={handleSetFuncDeleteItems}
              />
            )}
          </AppCard>
        </div>
      </AppPage>

      <ModalAddStation
        show={modalAddStationShowing}
        onClose={() => setModalAddStationShowing(false)}
        rootAreaId={currentUser.areaId}
      />
    </>
  );
}

export default RadioStationManagerScreen;
