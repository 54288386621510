import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import DataTable from "components/Table";
import AppPagination from "components/AppPagination";
import "./style.scss";
import providerApi from "api/providerApi";
import Global from "general/Global";
import ModalCreateProvider from "../ModalCreateProvider";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedProvider,
  setProviders,
  deleteProvider,
} from "pages/ProviderManager/providerSlice";
import ToastHelper from "general/helpers/ToastHelper";
import Utils from "general/utils/Utils";
import useSort from "hooks/useSort";
import ModalDelete from "components/AppModal/ModalDelete";
import CheckBox from "components/CheckBox";

function TableListProviders(props) {
  const { t } = useTranslation();

  // MARK: --- Params ---
  const {
    searchText,
    selectedItems,
    handleSetSelectedItems,
    handleSetFuncDeleteItems,
  } = props;

  const [page, setPage] = useState(1);
  const [step, setStep] = useState(Global.gDefaultPagination);
  const [editableProvider, setEditableProvider] = useState();
  const [deletableProvider, setDeletableProvider] = useState();
  const [modalCreateProviderShowing, setModalCreateProviderShowing] =
    useState(false);
  const [modalDeleteShowing, setModalDeleteShowing] = useState(false);
  const dispatch = useDispatch();
  const { providers } = useSelector((state) => state.provider);
  const [isLoading, setIsLoading] = useState(true);
  const { directionIndicator, sortOnClick } = useSort(
    providers,
    (sortedArray) => dispatch(setProviders(sortedArray))
  );

  const filteredProviders = useMemo(
    () => Utils.filter(providers, "name", searchText),
    [searchText, providers]
  );

  // MARK: --- Functions ---

  // Get providers
  async function getProviders() {
    setIsLoading(true);
    const res = await providerApi.getProviders();
    const { result, providers } = res.data;

    if (result == "success") {
      dispatch(setProviders(providers));
    }
    setIsLoading(false);
  }

  function handleDeleteProvider(id) {
    setModalDeleteShowing(true);
    setDeletableProvider(id);
  }

  // MARK: --- Hooks ---
  useEffect(() => {
    getProviders();
    handleSetFuncDeleteItems(() => (id) => handleDeleteProvider(id));
    return () => {
      handleSetSelectedItems([]);
    };
  }, []);

  return (
    <>
      <DataTable
        cols={[0, 25, 15, 25, 0, 20, 15]}
        headItems={[
          <CheckBox
            checked={
              filteredProviders.length === selectedItems.length &&
              filteredProviders.length !== 0
            }
            onCheck={() => handleSetSelectedItems(filteredProviders)}
            onUncheck={() => handleSetSelectedItems([])}
          />,
          <div
            className="sort-direction w-100 cursor-pointer"
            onClick={() => sortOnClick("name")}
          >
            <p>
              {t("Provider")}
              {directionIndicator("name")}
            </p>
          </div>,
          t("Contact"),
          t("Address"),
          t("NumberOfDevices"),
          t("API Key"),
          t("Manage"),
        ]}
        // row
        dataItems={filteredProviders
          ?.slice(step * (page - 1), step * page)
          .map((item, index) => {
            let isRowCheck = !!selectedItems?.find((provider) => provider.id === item.id);
            return {
              isRowCheck: isRowCheck,
              rowClick: () => {},
              data: [
                {
                  comp: (
                    <CheckBox
                      checked={isRowCheck}
                      onCheck={() =>
                        selectedItems?.find(
                          (provider) => provider.id === item.id
                        ) !== null &&
                        handleSetSelectedItems([...selectedItems, item])
                      }
                      onUncheck={() =>
                        handleSetSelectedItems(
                          selectedItems.filter(
                            (provider) => provider.id !== item.id
                          )
                        )
                      }
                    />
                  ),
                },
                {
                  comp: (
                    <div
                      className="d-flex flex-row align-items-center position-relative"
                      style={{ minWidth: 150 }}
                    >
                      <i className="fas fa-user-tie text-primary"></i>
                      <div
                        className="Bold_13 text-primary position-absolute w-100 top-50 translate-middle-y overflow-wrap pr-6"
                        style={{ left: 22 }}
                      >
                        {item.name}
                      </div>
                    </div>
                  ),
                  onClick: () => {
                    dispatch(setSelectedProvider(item));
                  },
                },
                {
                  comp: (
                    <div
                      className="position-relative"
                      style={{ minWidth: 120 }}
                    >
                      <p className="Regular_13 position-absolute w-100 top-50 translate-middle-y overflow-wrap">
                        {item.contact || ""}
                      </p>
                    </div>
                  ),
                  onClick: () => {
                    dispatch(setSelectedProvider(item));
                  },
                },
                {
                  comp: (
                    <div
                      className="position-relative"
                      style={{ minWidth: 120 }}
                    >
                      <p className="Regular_13 position-absolute w-100 top-50 translate-middle-y overflow-wrap">
                        {item.address || ""}
                      </p>
                    </div>
                  ),
                  onClick: () => {
                    dispatch(setSelectedProvider(item));
                  },
                },
                {
                  comp: (
                    <div className="w-100 d-flex justify-content-center">
                      <p className="Regular_13">
                        {item.deviceCount || 0}
                      </p>
                    </div>
                  ),
                  onClick: () => {
                    dispatch(setSelectedProvider(item));
                  },
                },
                {
                  comp: (
                    <div
                      className="position-relative"
                      style={{ minWidth: 120 }}
                    >
                      <p className="Regular_13 position-absolute w-100 top-50 translate-middle-y overflow-wrap">
                        {item.providerAPIKey || ""}
                      </p>
                    </div>
                  ),
                  onClick: () => {
                    dispatch(setSelectedProvider(item));
                  },
                },
                {
                  comp: (
                    <div style={{ width: 80 }}>
                      <i
                        className="fa fa-cog cursor-pointer mr-5 text-hover-primary"
                        onClick={() => {
                          setModalCreateProviderShowing(true);
                          setEditableProvider(item);
                        }}
                      />
                      <i
                        className="fa fa-trash-alt cursor-pointer mr-5 text-hover-danger"
                        onClick={() => {
                          handleDeleteProvider(item);
                        }}
                      ></i>
                      <i
                        className="fas fa-copy cursor-pointer text-hover-primary"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            item.providerAPIKey || ""
                          );
                          ToastHelper.showSuccess(t("Copied"));
                        }}
                      ></i>
                    </div>
                  ),
                },
              ],
            };
          })}
        step={step}
        onRefresh={() => getProviders()}
        isLoading={isLoading}
      />

      <ModalDelete
        show={modalDeleteShowing}
        title={t("DeleteProvider")}
        text={
          Array.isArray(deletableProvider)
            ? `Bạn có chắc muốn xóa ${deletableProvider.length} nhà cung cấp không?`
            : `Bạn có chắc muốn xóa nhà cung cấp ${deletableProvider?.name} không?`
        }
        onClose={() => setModalDeleteShowing(false)}
        onSubmit={async () => {
          const res = await providerApi.deleteProvider(
            Array.isArray(deletableProvider)
              ? deletableProvider
              : deletableProvider.id
          );
          const { result } = res.data;

          if (result == "success") {
            ToastHelper.showSuccess(t("DeletedProviderSuccessfully"));
            dispatch(
              deleteProvider(
                Array.isArray(deletableProvider)
                  ? deletableProvider
                  : deletableProvider.id
              )
            );
            handleSetSelectedItems([]);
          }
        }}
      />

      <AppPagination
        className="bg-white"
        total={filteredProviders.length}
        step={Global.gAvailablePagination}
        curStep={step}
        curPage={page}
        setPage={setPage}
        setStep={setStep}
      />

      <ModalCreateProvider
        show={modalCreateProviderShowing}
        onClose={() => setModalCreateProviderShowing(false)}
        provider={editableProvider}
      />
    </>
  );
}

export default TableListProviders;
