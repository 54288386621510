import React, { useEffect, useState } from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
// import { TokenService } from "../../services/storage.service";
import "./styles.scss";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { unwrapResult } from "@reduxjs/toolkit";
import PreferenceKeys from "general/constants/PreferenceKeys";
import Utils from "general/utils/Utils";
import FormikControl from "pages/Login/components/inputs/FormikControl";
import { thunkSignIn } from "pages/Login/authSlice";
import authApi from "api/authApi";
import { Toast } from "react-bootstrap";
import ToastHelper from "general/helpers/ToastHelper";

const ResetPasswordForm = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const errorMsg = useSelector((state) => state?.error?.msg);
  const [errMsgAPI, setErrMsgAPI] = useState(null);
  const [isHiddenPassword, setIsHiddenPassword] = useState(true);
  const [isHiddenNewPassword, setIsHiddenNewPassword] = useState(true);
  const [modalResetPassword, setModalResetPassword] = useState(false);
  const [nLogin, setNLogin] = useState(null);
  const [loading, setLoading] = useState(false);

  const passwordMaxLength = process.env.REACT_APP_PASSWORD_MAX_LENGTH;
  const passwordMinLength = process.env.REACT_APP_PASSWORD_MIN_LENGTH;
  const passwordHaveCapitalLetter = process.env.REACT_APP_PASSWORD_CAPITAL_LETTER;
  const passwordHaveNonCapitalLetter = process.env.REACT_APP_PASSWORD_NON_CAPITAL_LETTER;
  const passwordHaveNumber = process.env.REACT_APP_PASSWORD_NUMBER_LETTER;
  const [passwordRequirement, setPasswordRequirement] = useState(null);
  let initialPasswordErrorText = "Mật khẩu gồm ít nhất "+passwordMinLength +" kí tự";
  const [passwordRequirementErrorText, setPasswordRequirementErrorText] = useState(initialPasswordErrorText);
  let regExp = new RegExp(/^/);

  useEffect(() => {
    if(passwordHaveCapitalLetter == "true"){
      regExp = new RegExp(regExp.source + /(?=.*[A-Z])/.source);
      initialPasswordErrorText = initialPasswordErrorText + ", 1 kí tự viết hoa";
      setPasswordRequirementErrorText(initialPasswordErrorText);
    }
    if(passwordHaveNonCapitalLetter == "true"){
      regExp = new RegExp(regExp.source + /(?=.*[a-z])/.source);
      initialPasswordErrorText = initialPasswordErrorText + ", 1 kí tự viết thường";
      setPasswordRequirementErrorText(initialPasswordErrorText);
    }
    if(passwordHaveNumber == "true"){
      regExp = new RegExp(regExp.source + /(?=.*[0-9])/.source);
      initialPasswordErrorText = initialPasswordErrorText + ", 1 chữ số";
      setPasswordRequirementErrorText(initialPasswordErrorText);
    }
    setPasswordRequirement(regExp);
  }, []);

  useEffect(() => {
    document.getElementsByName("newPassword")[0].focus();
  }, []);

  const initialValues = {
    newPassword: "",
    password: "",
    resetToken: props.resetToken,
  };

  const validationSchema = Yup.object({
    newPassword: Yup.string()
      .min(passwordMinLength, passwordRequirementErrorText)
      .max(passwordMaxLength, t("PasswordTooLong"))
      .required(t("enterPass"))
      .matches(passwordRequirement, passwordRequirementErrorText)
      .matches(/^(?!.*[\s])/, "Có khoảng trắng trong mật khẩu"),
    password: Yup.string().oneOf(
      [Yup.ref("newPassword"), null],
      t("PasswordMustMatch")
    ),
  });

  const onSubmit = async (values, props) => {
    setLoading(true);
    console.log("OTP", values);
    try {
      let res = await authApi.resetPassword(values);
      if (res) {
        setLoading(false);
        console.log(res);
        let check = res.data;
        if (res.data.status == 0) {
          ToastHelper.showSuccess("Thay đổi thành công");
          history.replace("/dashboard");
        }
      }
    } catch (error) {}
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <div className="form-title mb-1">{t("ResetPassword")}</div>
              {errorMsg ? (
                <div className="login-form-error m-0">
                  {errorMsg.Description}
                </div>
              ) : null}
              <FormikControl
                control="input"
                type={isHiddenNewPassword ? "password" : "text"}
                label={
                  <p className="font-weight-normal font-size-sm line-height-sm mb-0">
                    {t("EnterNewPassword")}
                  </p>
                }
                placeholder={
                  !isHiddenNewPassword ? "" : t("passwordPlaceHolder")
                }
                name="newPassword"
                setIsHiddenPassword={setIsHiddenNewPassword}
                isHiddenPassword={isHiddenNewPassword}
              />
              {errMsgAPI === null ? null : (
                <p style={{ color: "red" }}>{errMsgAPI}</p>
              )}
              <FormikControl
                control="input"
                type={isHiddenPassword ? "password" : "text"}
                label={
                  <p className="font-weight-normal font-size-sm line-height-sm mb-0">
                    {t("ReenterNewPassword")}
                  </p>
                }
                placeholder={!isHiddenPassword ? "" : t("passwordPlaceHolder")}
                name="password"
                setIsHiddenPassword={setIsHiddenPassword}
                isHiddenPassword={isHiddenPassword}
              />
              <div className="login-form-divider" />
              <button
                className="login-button d-flex justify-content-center"
                type="submit button"
                disabled={!formik.isValid || loading}
              >
                {loading === false ? null : (
                  <span className="spinner-border spinner-border-sm mr-2 mt-1"></span>
                )}
                <p className="font-weight-bold text-white mb-0">
                  {t("ResetPassword")}
                </p>
              </button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ResetPasswordForm;
