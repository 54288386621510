import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as yup from 'yup';
import './style.scss'
import providerApi from 'api/providerApi';
import { addProvider, updateProvider } from 'pages/ProviderManager/providerSlice';
import ToastHelper from 'general/helpers/ToastHelper';
import BaseTextField from 'general/custom-fields/BaseTextField';

ModalCreateProvider.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    provider: PropTypes.object
};

ModalCreateProvider.defaultProps = {
    show: false,
    onClose: null,
    provider: null,
};

function ModalCreateProvider(props) {
    // MARK: --- Params ---
    const { show, onClose, provider } = props;
    const { t } = useTranslation();
    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: {
            ProviderName: '',
            Contact: '',
            Address: '',
            APIKey: '',
        },
        onSubmit: (values) => {
            if(provider)
                handleModifyProvider({
                    id: provider.id, 
                    name: values.ProviderName, 
                    apiKey: values.APIKey, 
                    contact: values.Contact, 
                    address: values.Address
                })     
            else handleAddProvider({
                name: values.ProviderName, 
                apiKey: values.APIKey, 
                contact: values.Contact, 
                address: values.Address
            })
            handleClose()
        },
        validationSchema: yup.object({
            ProviderName: yup.string().required(t('Required')),
        }),
    });

    useEffect(() => {
        if(provider){
            formik.getFieldHelpers('ProviderName').setValue(provider?.name)
            formik.getFieldHelpers('Contact').setValue(provider?.contact)
            formik.getFieldHelpers('Address').setValue(provider?.address)
            formik.getFieldHelpers('APIKey').setValue(provider?.providerAPIKey)
        }
    },[provider])

    // MARK: --- Functions ---
    function handleClose() {
        if (onClose) {
            onClose();
        }
    }

    async function handleAddProvider(params) {
        const res = await providerApi.addProvider(params);
        const { result, station } = res.data;

        if(result == "success"){
            dispatch(addProvider(station))
            ToastHelper.showSuccess(t('CreatedProviderSuccessfully'))
        }
    }

    async function handleModifyProvider(params) {
        const res = await providerApi.modifyProvider(params);
        const { result } = res.data;

        if(result == "success"){
            dispatch(updateProvider(params))
            ToastHelper.showSuccess(t('UpdatedProviderSuccessfully'))
        }
    }

    return (
        <div>
            <Modal
                className='font-weight-bold'
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={() => {
                    handleClose();
                }}
            >
                <Modal.Header className='d-flex justify-content-center'>
                    <Modal.Title>
                        <p className="Bold_13">
                            {t('Provider')}
                        </p>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='bg-light'>
                    
                    <BaseTextField
                        className='FieldName Normal_12 pb-5'
                        name='ProviderName'
                        label={t('ProviderName')}
                        placeholder={t('ProviderName')}
                        fieldProps={formik.getFieldProps('ProviderName')}
                        fieldHelpers={formik.getFieldHelpers('ProviderName')}
                        fieldMeta={formik.getFieldMeta('ProviderName')}
                    />

                    <BaseTextField
                        className='FieldName Normal_12 pb-5'
                        name='Contact'
                        label={t('Contact')}
                        placeholder={t('Contact')}
                        fieldProps={formik.getFieldProps('Contact')}
                        fieldHelpers={formik.getFieldHelpers('Contact')}
                        fieldMeta={formik.getFieldMeta('Contact')}
                    />

                    <BaseTextField
                        className='FieldName Normal_12 pb-5'
                        name='Address'
                        label={t('Address')}
                        placeholder={t('Address')}
                        fieldProps={formik.getFieldProps('Address')}
                        fieldHelpers={formik.getFieldHelpers('Address')}
                        fieldMeta={formik.getFieldMeta('Address')}
                    />

                    <BaseTextField
                        className='FieldName Normal_12'
                        name='APIKey'
                        label={t('API Key')}
                        placeholder={t('API Key')}
                        fieldProps={formik.getFieldProps('APIKey')}
                        fieldHelpers={formik.getFieldHelpers('APIKey')}
                        fieldMeta={formik.getFieldMeta('APIKey')}
                    />

                </Modal.Body>
                <Modal.Footer className='d-flex justify-content-center'>
                    <div className='row w-100'>
                        <div className='col-6'>
                            <a 
                                className='w-100 btn btn-light  font-weight-bold'
                                onClick={() => handleClose()}
                            >
                                {t('Cancel')}
                            </a>
                        </div>

                        <div className='col-6'>
                            <a 
                                className='w-100 btn btn-primary font-weight-bold'
                                onClick={() => { formik.handleSubmit() }}
                            >
                                {t('Save')}
                            </a>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default ModalCreateProvider;