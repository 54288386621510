import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';

import {thunkCreateStation} from 'pages/RadioStationManager/stationSlice';

import SelectArea from "components/SelectArea/SelectArea";
import BaseTextField from 'general/custom-fields/BaseTextField';

import './style.scss';


ModalAddStation.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    rootAreaId: PropTypes.number,
};

ModalAddStation.defaultProps = {
    show: false,
    onClose: null,
};

function ModalAddStation(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [selectedArea, setSelectedArea] = useState(null);

    // MARK: --- Params ---
    const { show, onClose, rootAreaId } = props;


    // MARK: --- Formik ---
    const formik = useFormik({
        initialValues: {
            name: '',
            areaId: '',
        },
        validationSchema: yup.object({
            name: yup.string().required(t('Required')),
            areaId: yup.number().required(t('Required')),
        }),
        onSubmit: (values) => {
            dispatch( thunkCreateStation(values) );
            handleClose();
            setSelectedArea(null)
            formik.handleReset();
        },
    });


    // MARK: --- Functions ---
    function handleClose() {
        if (onClose) {
            onClose();
        }
    }


    return (
        <Modal
            className='AddStation font-weight-bold'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={() => {
                handleClose();
            }}
        >
            <Modal.Header className='d-flex justify-content-center'>
                <Modal.Title>
                    <p className='Bold_13'>{t('AddStation')}</p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='bg-light'>
                <BaseTextField
                    className='StationName Normal_12'
                    name='name'
                    label={t('StationName')}
                    placeholder={t('StationName')}
                    
                    fieldProps={formik.getFieldProps('name')}
                    fieldHelpers={formik.getFieldHelpers('name')}
                    fieldMeta={formik.getFieldMeta('name')}
                />
                
                <div className='mt-3 mb-1'
                    style={{
                        fontSize: 12,
                        fontWeight: 500,
                        color: '#9EA5BD',
                    }}
                >{t('Area')}</div>
                <SelectArea
                  onSelect={(area) => {
                    formik.getFieldHelpers('areaId').setValue(area.AreaId)
                    setSelectedArea(area)
                  }}
                  rootAreaId={rootAreaId}
                  className="bg-white rounded"
                  selectedArea={selectedArea}
                />
            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
                <div className='row w-100'>
                    <div className='col-6'>
                        <a 
                            className='w-100 btn btn-light  font-weight-bold'
                            onClick={() => handleClose()}
                        >
                            {t('Cancel')}
                        </a>
                    </div>

                    <div className='col-6'>
                        <a 
                            className='w-100 btn btn-primary font-weight-bold'
                            onClick={() => { formik.handleSubmit() }}
                        >
                            {t('Save')}
                        </a>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalAddStation;
