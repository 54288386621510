import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Utils from "general/utils/Utils";

import AppPage from "components/AppPage";
import AppCard from "components/AppCard";
import AreaFilter from "components/AreaFilter";

import areaApi from "api/areaApi";
import { AppImages } from "general/constants/AppResource";
import NavBar from "components/NavBar";
import AppTabs from "components/AppTabs";
import stationApi from "api/stationApi";
import DevicesTable from "components/DevicesTable";
import { useParams } from "react-router-dom";
import DevicesInfo from "pages/DeviceDetail/components/DeviceInfo";
import ScheduleInfo from "pages/DeviceDetail/components/ScheduleInfo";
import deviceApi from "api/deviceApi";
import { thunkGetAreas } from "pages/AreaManager/areaSlice";
import bulletinBoardApi from "api/bulletinBoardApi";

DeviceDetailScreen.propTypes = {};

function DeviceDetailScreen(props) {
  const { t } = useTranslation();
  let { deviceType, id } = useParams();
  const dispatch = useDispatch();

  const [currentDevice, setCurrentDevice] = useState({});

  let tabs = [t("DeviceInfo"), t("Schedule")];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);

  // MARK: --- Functions ---
  function handleSelectedTab(tab) {
    setSelectedTab(tab);
  }

  // MARK: --- Hooks ---
  useEffect(() => {
    dispatch(thunkGetAreas({}));
  }, []);
  useEffect(() => {
    deviceType == "bulletin-board"
      ? bulletinBoardApi
          .getBulletinBoardById(id)
          .then((data) => {
            if (data.data.status == 0) { 
              setCurrentDevice(data.data.bulletinBoard);
            }
          })
          .catch()
      : deviceApi
          .getDeviceById(id)
          .then((data) => {
            if (data.data.status == 0) {
              setCurrentDevice(data.data.speaker);
            }
          })
          .catch();
  }, [deviceType]);
  
  return (
    <>
      <AppPage
        backgroundImage={AppImages.imgBackground4}
        headerTitle="Chi tiết thiết bị"
      >
        <NavBar navBarTitle="Chi tiết thiết bị"></NavBar>
        <div className="d-flex flex-column flex-column-fluid my-2">
          <AppCard title={id}>
            <div className="w-100 bg-white">
              <div className="d-flex justify-content-between align-items-end">
                {deviceType == "bulletin-board" ? null : (
                  <AppTabs
                    tabs={tabs.reverse()}
                    activeTab={selectedTab}
                    handleClick={handleSelectedTab}
                    className="flex-grow-1"
                  />
                )}
              </div>
            </div>
            {console.log(selectedTab, tabs[0], selectedTab === tabs[0])}
            {selectedTab ===
            (deviceType == "bulletin-board"
              ? tabs[0]
              : tabs[tabs.length - 1]) ? (
              <DevicesInfo
                deviceType={deviceType}
                configured={true}
                device={currentDevice}
              />
            ) : (
              <ScheduleInfo></ScheduleInfo>
            )}
          </AppCard>
        </div>
      </AppPage>
    </>
  );
}

export default DeviceDetailScreen;
