import { sha256 } from "js-sha256";
import store from "libs/storage/store";
import moment from "moment";
import React from "react";
import { useLocation } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const Utils = {
  // Get full url
  getFullUrl: (url) => {
    if (url && !url.startsWith("http") && !url.startsWith("blob")) {
      return `${process.env.REACT_APP_BASE_URL}${url}`;
    }
    return url;
  },

  // Check object empty
  isObjectEmpty: (obj) => {
    return (
      Utils.isObjectNull(obj) ||
      (Object.keys(obj).length === 0 && obj.constructor === Object)
    );
  },

  // Check object null|undefine
  isObjectNull: (obj) => {
    return obj === null || obj === undefined || obj === "NULL";
  },

  // convert first character of string to uppercase
  convertFirstCharacterToUppercase: (stringToConvert) => {
    var firstCharacter = stringToConvert.substring(0, 1);
    var restString = stringToConvert.substring(1);
    return firstCharacter.toUpperCase() + restString;
  },

  /* sleep */
  sleep: async (ms: Number) => {
    return new Promise((resolve, reject) => {
      setTimeout(resolve, ms);
    });
  },
  /* validate url */
  validateUrl(value: String) {
    if (!_.isString(value)) return false;
    return /^(ftp|http|https):\/\/[^ "]+$/.test(value);
    // return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value)
  },

  // format date time
  formatDateTime: (sDateTime, sFormat = "DD/MM/YYYY HH:mm") => {
    return moment(sDateTime).format(sFormat);
  },

  // Change empty to null
  formatEmptyKey: (items) => {
    for (const [key, value] of Object.entries(items)) {
      if (value === "") {
        items[key] = null;
      }
    }
  },

  // remove null key
  removeNullKey: (items) => {
    for (const [key, value] of Object.entries(items)) {
      if (value === null) {
        delete items[key];
      }
    }
  },

  // Delete null
  formatNullKey: (items) => {
    for (const [key, value] of Object.entries(items)) {
      if (value === null) {
        delete items[key];
      }
    }
  },
  /**
   * return youtube video id if success
   */
  matchYoutubeUrl(url: String) {
    if (url) {
      var p =
        /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      var matches = url.match(p);
      if (matches) {
        return matches[1];
      }
    }
    return false;
  },
  async hash(text: String) {
    if (text.length > 0) {
      return sha256(text);
    }
    return null;
  },

  // nghia
  /**
   * Return a function to use in `[Dict].sort()`
   *
   * Sort direction base on two params:
   * * `propName` (string): Dict's field be sorted on
   * * `type` (string): type of field
   *
   * Ex:
   * ```js
   * import ascSort from '...'
   * Dict.sort(ascSort('id', 'number'))
   * Dict.sort(ascSort('name', 'string'))
   * ```
   */
  ascSort(propName, type) {
    if (type === "string") {
      return function (a, b) {
        return a[propName]
          .toLowerCase()
          .localeCompare(b[propName].toLowerCase());
      };
    }

    if (type === "number") {
      return function (a, b) {
        return a[propName] - b[propName];
      };
    }
  },

  toLowerCaseNonAccent(str) {
    str = str
      .toLowerCase()
      .replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a")
      .replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e")
      .replace(/ì|í|ị|ỉ|ĩ/g, "i")
      .replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o")
      .replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y")
      .replace(/đ/g, "d")
      .replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, "") // Huyền sắc hỏi ngã nặng
      .replace(/\u02C6|\u0306|\u031B/g, ""); // Â, Ê, Ă, Ơ, Ư
    return str;
  },

  /**
   * Params:
   * * list (`object[]`): list object need to be filtered
   * * filterOn (`string`): field names to filter on
   * * filterText (`string`): text use to filter
   *
   * Alway use with useMemo. Example:
   * ```js
   * import { useMemo } from "react";
   * const filteredDevices = useMemo(() => Utils.filter(devices, 'name', searchText), [searchText, devices])
   * ```
   */
  filter(list, filterOn, filterText) {
    if (filterText.length && list.length) {
      const filterTextLowerCaseNonAccent =
        this.toLowerCaseNonAccent(filterText);
      if (typeof list[0][filterOn] == "string")
        return list.filter((item) =>
          this.toLowerCaseNonAccent(item[filterOn]).includes(
            filterTextLowerCaseNonAccent
          )
        );
      else
        return list.filter((item) =>
          this.toLowerCaseNonAccent(item[filterOn].toString()).includes(
            filterTextLowerCaseNonAccent
          )
        );
    }
    return list;
  },
};

/**
 * custom hook to access query
 * @important follow hook rules
 * @returns {URLSearchParams}
 */
export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const randStr = function () {
  return Math.random().toString(36).substr(2); // remove `0.`
};

export function decodeHtml(html: any) {
  if (!_.isString(html)) return html;
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

export function addZero(value, digits) {
  digits = digits || 2;

  let str = value.toString();
  let size = 0;

  size = digits - str.length + 1;
  str = new Array(size).join("0").concat(str);

  return str;
}

export function formatDuration(durationSecond) {
  let duration = Number(durationSecond);
  if (duration) {
    let days = Math.trunc(duration / 86400);
    let hours = Math.trunc(duration / 3600) % 24;
    let minutes = Math.trunc(duration / 60) % 60;
    let seconds = addZero(Math.trunc(duration) % 60);

    if (days)
      return (
        days + ":" + addZero(hours) + ":" + addZero(minutes) + ":" + seconds
      );
    if (hours) return addZero(hours) + ":" + addZero(minutes) + ":" + seconds;
    return addZero(minutes) + ":" + seconds;
  }
  return "--:--";
}

export const FILE_OBJ_LOCAL_ATTRIBUTES = ["file", "playing", "src", "cleanup"];

export async function formatFileObj(file) {
  window.URL = window.URL || window.webkitURL;
  let duration = 0,
    playDuration = 0,
    id = null,
    playing = false,
    src,
    savedName = "";
  if (file instanceof File) {
    id = uuidv4();
    ({ duration, src } = await getFileDuration(file));
    playDuration = duration;
  } else {
    // file is url
    ({ id, duration, playDuration, src, savedName } = file);
    src = `${process.env.REACT_APP_BASE_URL}${src}`;
  }

  return {
    id,
    file,
    duration: Number(duration),
    playDuration: Number(playDuration),
    playing,
    src,
    savedName, // tên file đã lưu trên sv
    cleanup: () => {
      URL.revokeObjectURL(src);
    },
  };
}

export async function getFileDuration(file) {
  return new Promise((resolve) => {
    window.URL = window.URL || window.webkitURL;
    let audio = document.createElement("audio");
    audio.onloadedmetadata = function () {
      if (audio.duration === Infinity) {
        audio.currentTime = 1e101;
        audio.ontimeupdate = function () {
          audio.ontimeupdate = null;
          audio.currentTime = 0;
          resolve({
            duration: audio.duration,
            src: audio.src,
          });
          audio.remove();
        };
      } else {
        resolve({
          duration: audio.duration,
          src: audio.src,
        });
        audio.remove();
      }
    };
    audio.src = file instanceof File ? URL.createObjectURL(file) : file;
  });
}

export function getAllSupportedMimeTypes(mediaTypes = ["audio"]) {
  const FILE_EXTENSIONS = ["webm", "ogg", "mp4", "x-matroska"];
  const CODECS = [
    "vp9",
    "vp9.0",
    "vp8",
    "vp8.0",
    "avc1",
    "av1",
    "h265",
    "h.265",
    "h264",
    "h.264",
    "opus",
  ];

  return [
    ...new Set(
      FILE_EXTENSIONS.flatMap((ext) =>
        CODECS.flatMap((codec) =>
          mediaTypes.flatMap((mediaType) => [
            {
              mimeType: `${mediaType}/${ext}`,
              ext,
            },
            {
              mimeType: `${mediaType}/${ext};codecs:${codec}`,
              ext,
            },
            {
              mimeType: `${mediaType}/${ext};codecs=${codec}`,
              ext,
            },
            {
              mimeType: `${mediaType}/${ext};codecs:${codec.toUpperCase()}`,
              ext,
            },
            {
              mimeType: `${mediaType}/${ext};codecs=${codec.toUpperCase()}`,
              ext,
            },
          ])
        )
      )
    ),
  ].filter(({ mimeType }) => MediaRecorder.isTypeSupported(mimeType));
}

export async function getMicDevices() {
  return new Promise((resolve) => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
      console.error("enumerateDevices() not supported.");
      resolve([]);
    } else {
      navigator.mediaDevices
        .enumerateDevices()
        .then(function (devices) {
          resolve(
            devices.filter(function (device) {
              return device.kind === "audioinput" && device.deviceId;
            })
          );
        })
        .catch(function (err) {
          console.error(`${err.name}: ${err.message}`);
          resolve([]);
        });
    }
  });
}

export function formatBoardBulletinFileObj(file) {
  window.URL = window.URL || window.webkitURL;
  let id = null,
    src,
    savedName = "";
  if (file instanceof File) {
    id = uuidv4();
    src = URL.createObjectURL(file);
  } else {
    // file is url
    ({ id, src, savedName } = file);
    src = `${process.env.REACT_APP_BASE_URL}${src}`;
  }

  return {
    id,
    file,
    src,
    savedName, // tên file đã lưu trên sv
    cleanup: () => {
      URL.revokeObjectURL(src);
    },
  };
}

//Area Helper
export function formatArea(area) {
  return {
    text: area.name ?? area.text,
    AreaName: area.name ?? area.AreaName,
    AreaId: area.id ?? area.AreaId,
  };
}

export function findAreaById(
  childAreaId,
  areaObj = store.getState().area.allAreas
) {
  if (Array.isArray(areaObj) && areaObj.length) {
    for (const area of areaObj) {
      if (area.id == childAreaId) {
        return formatArea(area);
      }
    }
  }
  return null;
}

export function getAreaJSTreeStructure(
  rootAreaId,
  option = {},
  isRecursiveCall = false
) {
  let allAreas = store.getState().area.allAreas;
  let rootArea = findAreaById(rootAreaId);
  if (rootArea == null) {
    return null;
  }
  if (option.queryString && option.queryString.length) {
    if (!option.queryStringNormalized) {
      option.queryString = normalizeString(option.queryString);
      option.queryStringNormalized = true;
    }
  }

  let { expandChildDepth, selectedArea } = option;
  if (!expandChildDepth && expandChildDepth !== 0) {
    expandChildDepth = 1;
  }
  // let areaTree = {text, AreaName, AreaId};
  let areaTree = {};
  if (expandChildDepth || (selectedArea && selectedArea.AreaId == rootAreaId)) {
    areaTree.state = {
      opened: true,
    };
    expandChildDepth--;
    if (expandChildDepth < 0) expandChildDepth = 0;
  }

  areaTree = {
    ...areaTree,
    ...rootArea,
  };

  areaTree.children = [];

  for (const area of allAreas) {
    if (area.parentId == rootArea.AreaId) {
      let childStruct = getAreaJSTreeStructure(
        area.id,
        {
          ...option,
          expandChildDepth,
        },
        true
      );
      if (childStruct) {
        areaTree.children.push(childStruct.areaTree);
        if (childStruct.isOpen) {
          if (!areaTree.state) {
            areaTree.state = {};
          }
          areaTree.state.opened = true;
        }
      }
    }
  }
  if (areaTree.children.length === 0) {
    delete areaTree.children;
  }

  // if (!areaOnly) {
  //     if (Array.isArray(areaObj.Speakers) && areaObj.Speakers.length) {
  //         if (!areaTree.children) {
  //             areaTree.children = [];
  //         }
  //         areaTree.children = areaTree.children.concat(areaObj.Speakers.map(function (speaker) {
  //             return {
  //                 text: speaker.IMEI,
  //                 icon: "fas fa-volume-up text-success"
  //             }
  //         }));
  //     }
  // }

  if (option.queryString && option.queryString.length) {
    if (!normalizeString(rootArea.AreaName).includes(option.queryString)) {
      if (!(areaTree.children && areaTree.children.length)) {
        return null;
      }
    } else {
      areaTree.search = true;
      if (!areaTree.state) {
        areaTree.state = {};
      }
      areaTree.state.opened = true;
    }
  }
  if (isRecursiveCall) {
    return {
      areaTree,
      isOpen: areaTree?.state?.opened,
    };
  }
  if (option.queryString && option.queryString.length) {
    return transformAreaTreeSearchResult(areaTree);
  }

  return areaTree;
}

export function transformAreaTreeSearchResult(areaObj, prefix = []) {
  let results = [];
  // prefix=prefix.slice(0);
  prefix.push(areaObj.AreaName);
  if (areaObj.search) {
    areaObj.text = prefix.slice(0).reverse().join(", ");
    results.push(areaObj);
  }
  if (areaObj.children && areaObj.children.length) {
    for (const area of areaObj.children) {
      results = results.concat(
        transformAreaTreeSearchResult(area, prefix.slice(0))
      );
    }
  }
  if (results[0]) {
    if (results[0].children) {
      delete results[0].children;
    }
  }
  return results;
}

export function normalizeString(string) {
  // if (!string) return "";
  // return ("" + string).toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
  return _.deburr(string)
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/\s+/g, " ")
    .trim();
}

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}
export function sizeToCap(totalSize) {
  if (totalSize > 1000000000000) {
    return Math.ceil(totalSize / 1000000000).toString() + " GB";
  }
  if (totalSize > 1000000) {
    return Math.ceil(totalSize / 1000000).toString() + " MB";
  }
  if (totalSize > 1000) {
    return Math.ceil(totalSize / 1000).toString() + " KB";
  }
  if (totalSize <= 1000) {
    return totalSize.toString() + " Bytes";
  }
}
export function buildFormData(formData, data, parentKey) {
  if (
    data &&
    typeof data === "object" &&
    !(data instanceof Date) &&
    !(data instanceof File) &&
    !moment.isMoment(data)
  ) {
    Object.keys(data).forEach((key) => {
      buildFormData(
        formData,
        data[key],
        parentKey ? `${parentKey}[${key}]` : key
      );
    });
  } else {
    if (typeof data === "boolean") {
      if (!data) return;
    }
    const value =
      data == null ? "" : moment.isMoment(data) ? data.toISOString() : data;

    formData.append(parentKey, value);
  }
}

export function buildURL(url) {
  return `${process.env.REACT_APP_BASE_URL}${url}`;
}

export default Utils;
