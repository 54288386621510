import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as yup from 'yup';

import {thunkUpdateStation} from 'pages/RadioStationManager/stationSlice';
import BaseTextField from 'general/custom-fields/BaseTextField';


ModalUpdateStation.propTypes = {
    show: PropTypes.bool,
    onClose: PropTypes.func,
    station: PropTypes.object,
};

ModalUpdateStation.defaultProps = {
    show: false,
    onClose: null,
    station: null,
};

function ModalUpdateStation(props) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // MARK: --- Params ---
    const { show, onClose, station } = props;


    // MARK: --- Formik ---
    const formik = useFormik({
        initialValues: {
            id: '',
            name: '',
        },
        validationSchema: yup.object({
            id: yup.number().required(t('Required')),
            name: yup.string().required(t('Required')),
        }),
        onSubmit: (values) => {
            dispatch( thunkUpdateStation(values) );
            handleClose();
            formik.handleReset();
        },
    });


    // MARK: --- Functions ---
    function handleClose() {
        if (onClose) {
            onClose();
        }
    }


    // MARK: --- Hooks ---
    useEffect(() => {
        if (station) {
            formik.getFieldHelpers('id').setValue(station.id);
            formik.getFieldHelpers('name').setValue(station.name)
        }
    }, [station]);


    return (
        <Modal
            className='AddStation font-weight-bold'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={show}
            onHide={() => {
                handleClose();
            }}
        >
            <Modal.Header className='d-flex justify-content-center'>
                <Modal.Title>
                    <p className='Bold_13'>{t('ModifyStation')}</p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className='bg-light'>

                <BaseTextField
                    className='StationName Normal_12 pb-5'
                    name='name'
                    label={t('StationName')}
                    options={t('StationName')}
                    
                    fieldProps={formik.getFieldProps('name')}
                    fieldHelpers={formik.getFieldHelpers('name')}
                    fieldMeta={formik.getFieldMeta('name')}
                />

            </Modal.Body>
            <Modal.Footer className='d-flex justify-content-center'>
                <div className='row w-100'>
                    <div className='col-6'>
                        <a 
                            className='w-100 btn btn-light  font-weight-bold'
                            onClick={() => handleClose()}
                        >
                            {t('Cancel')}
                        </a>
                    </div>

                    <div className='col-6'>
                        <a 
                            className='w-100 btn btn-primary font-weight-bold'
                            onClick={() => { formik.handleSubmit() }}
                        >
                            {t('Save')}
                        </a>
                    </div>
                </div>
            </Modal.Footer>
        </Modal>
    );
}

export default ModalUpdateStation;
