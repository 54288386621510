import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Utils from "general/utils/Utils";
import AppPage from "components/AppPage";
import AppCard from "components/AppCard";
import AreaTable from "pages/AreaManager/components/AreaTable";
import ModalCreateArea from "../../components/ModalCreateArea";
import areaApi from "api/areaApi";
import { AppImages } from "general/constants/AppResource";
import NavBar from "components/NavBar";
import SelectArea from "components/SelectArea/SelectArea";
import BaseSearchBar from "general/custom-fields/BaseSearchBar";
import { deleteArea } from "pages/AreaManager/areaSlice";
import ToastHelper from "general/helpers/ToastHelper";

AreaManagerHomeScreen.propTypes = {};

function AreaManagerHomeScreen(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // MARK: --- Params ---
  const [modalCreateAreaShowing, setModalCreateAreaShowing] = useState(false);
  const { allAreas } = useSelector((state) => state.area);
  const [selectedArea, setSelectedArea] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [funcDeleteItems, setFuncDeleteItems] = useState(() => () => {});
  const currentUser = useSelector((state) => state.auth.current);
  // MARK: --- Functions ---
  // useEffect(() => {
  //   setSelectedArea(findAreaById(currentUser.areaId));
  // }, [currentUser]);

  function handleSetSelectedItems(items) {
    setSelectedItems(items);
  }

  function handleSetFuncDeleteItems(func) {
    setFuncDeleteItems((id) => func(id));
  }

  return (
    <>
      <AppPage
        backgroundImage={AppImages.areaBackground}
        headerTitle={t("AreaManagement")}
      >
        <NavBar navBarTitle={t("AreaManagement")}></NavBar>
        <div className="d-flex flex-column flex-column-fluid my-2">
          <AppCard
            title={t("AreaList")}
            buttonText={t("AddArea")}
            clickAdd={() => {
              setModalCreateAreaShowing(true);
            }}
            headerRight={
                <BaseSearchBar
                  name="searchBar"
                  placeholder={t("Search")}
                  onSubmit={setSearchText}
                  className="display-smaller-md-none w-md-200px w-xl-250px w-xxxl-300px mr-6"
                />
              }
            additionalHeader={
              <>
                <div className="display-bigger-md-none col-12 px-0">
                  <BaseSearchBar
                    name="searchBar"
                    placeholder={t("Search")}
                    onSubmit={setSearchText}
                    className="w-100"
                  />
                </div>
                <div className="d-flex flex-row w-100">
                  {!!selectedItems.length && (
                    <button
                      className="btn btn-light-danger mr-2 d-flex flex-row align-items-center mt-6"
                      onClick={(e) => {
                        e.preventDefault();
                        let params = selectedItems.map((item) => item.id);
                        funcDeleteItems(params);
                      }}
                    >
                      <i className="fas fa-folder"></i>
                      <p
                        className="font-weight-bold text-capitalize m-0"
                        style={{ fontSize: 13 }}
                      >
                        {`${t("Delete")} ${selectedItems.length}`}
                      </p>
                    </button>
                  )}
                </div>
              </>
            }
          >
            <AreaTable
              searchText={searchText}
              selectedArea={selectedArea}
              selectedItems={selectedItems}
              setSelectedArea={setSelectedArea}
              handleSetSelectedItems={handleSetSelectedItems}
              handleSetFuncDeleteItems={handleSetFuncDeleteItems}
            />
          </AppCard>
        </div>
      </AppPage>

      <ModalCreateArea
        show={modalCreateAreaShowing}
        onClose={() => setModalCreateAreaShowing(false)}
      />
    </>
  );
}

export default AreaManagerHomeScreen;
