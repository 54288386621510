import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";

const providerSlice = createSlice({
    name: 'provider',
    initialState: {
        isPending: false,
        providers: [],
        accounts: [],
        selectedProvider: null,
        selectedAccount: null,
    },
    reducers: {
        setProviders: (state, action) => {
            state.providers = action.payload
        },
        setAccounts: (state, action) => {
            state.accounts = action.payload
        },
        addProvider: (state, action) => {
            state.providers = [...state.providers, action.payload]
        },
        addAccount: (state, action) => {
            state.accounts = [...state.accounts, action.payload]
        },
        updateProvider: (state, action) => {
            for (let i = 0; i < state.providers.length; i++)
                if(state.providers[i].id === action.payload.id) {
                    state.providers[i].name = action.payload.name;
                    state.providers[i].providerAPIKey = action.payload.apiKey;
                    state.providers[i].contact = action.payload.contact;
                    state.providers[i].address = action.payload.address;
                    break;
                }
        },
        updateAccount: (state, action) => {
            for (let i = 0; i < state.accounts.length; i++)
                if(state.accounts[i].id === action.payload.id) {
                    state.accounts[i].serverUrl = action.payload.serverUrl;
                    state.accounts[i].account = action.payload.account;
                    state.accounts[i].password = action.payload.password;
                    break;
                }
        },
        deleteProvider: (state, action) => {
            if(!Array.isArray(action.payload))
                state.providers = state.providers.filter(item => item.id !== action.payload)
            else for (let provider of action.payload)
                state.providers = state.providers.filter(item => item.id !== provider)
        },
        deleteAccount: (state, action) => {
            if(!Array.isArray(action.payload))
                state.accounts = state.accounts.filter(item => item.id !== action.payload)
            else for (let account of action.payload)
                state.accounts = state.accounts.filter(item => item.id !== account)
        },
        setSelectedProvider: (state, action) => {
            state.selectedProvider = action.payload
        },
        setSelectedAccount: (state, action) => {
            state.selectedAccount = action.payload
        },
    },
    extraReducers: {

    }
})

const { actions, reducer } = providerSlice;

export const { 
    setSelectedProvider, 
    setSelectedAccount, 
    setProviders, 
    setAccounts, 
    addProvider, 
    addAccount,
    updateProvider,
    updateAccount,
    deleteProvider,
    deleteAccount,
} = actions;
export default reducer;
