import React from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import NotFound from "components/NotFound";
import DeviceManagerScreen from "./screens/DeviceManagerScreen";
import DeviceDetailScreen from "pages/DeviceDetail/screens/DeviceDetailScreen";
import DeviceConfiguredScreen from "pages/DeviceDetail/screens/DeviceConfigureScreen";
import BulletinBoardManagerScreen from "./screens/BulletinBoardScreen";

function DeviceManager(props) {
  // MARK: --- Params ---
  const match = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${match.url}/speaker`} component={DeviceManagerScreen} />
      <Route exact path={`${match.url}/bulletin-board`} component={BulletinBoardManagerScreen} />
      <Route exact path={`${match.url}/:deviceType/:id`} component={DeviceDetailScreen} />
      <Route exact path={`${match.url}/:deviceType/configure/:ids`} component={DeviceConfiguredScreen} />  
      <Route component={NotFound} />
    </Switch>
  );
}

export default DeviceManager;
