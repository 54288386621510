import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Utils, { findAreaById } from "general/utils/Utils";

import AppPage from "components/AppPage";
import AppCard from "components/AppCard";
import AreaFilter from "components/AreaFilter";
import areaApi from "api/areaApi";
import { AppIcons, AppImages } from "general/constants/AppResource";
import NavBar from "components/NavBar";
import AppTabs from "components/AppTabs";
import stationApi from "api/stationApi";
import DevicesTable from "components/DevicesTable";
import SelectArea from "components/SelectArea/SelectArea";
import deviceApi from "api/deviceApi";
import BaseSearchBar from "general/custom-fields/BaseSearchBar";
import ToastHelper from "general/helpers/ToastHelper";
import ModalAddDevice from "pages/DeviceManager/components/ModalAddDevice";
import bulletinBoardApi from "api/bulletinBoardApi";

BulletinBoardManagerScreen.propTypes = {};

function BulletinBoardManagerScreen(props) {
  const { t } = useTranslation();

  // MARK: --- Params ---
  const currentUser = useSelector((state) => state.auth.current);
  const devicesTableRef = useRef();
  const [areasByUser, setAreasByUser] = useState([]);
  const [currentArea, setCurrentArea] = useState();
  const [selectedArea, setSelectedArea] = useState();
  const [currentAreaId, setCurrentAreaId] = useState();
  const [searchText, setSearchText] = useState("");
  let tabs = [t("BulletinBoard"), t("NotConfiguredDevice")];
  const [selectedTab, setSelectedTab] = useState(tabs[0]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [numberOfSelectedDevices, setNumberOfSelectedDevices] = useState(null);
  const [modalAddDeviceShowing, setModalAddDeviceShowing] = useState(false);
  const deleteDevice = async (params) => {
    let deviceIds = params;
    let res = await deviceApi.delete({ deviceIds: deviceIds });
    if (res) {
      if (res.data.result === "success") {
        ToastHelper.showSuccess("Xóa thiết bị thành công");
        setSelectedItems([]);
      } else {
        ToastHelper.showError("Xóa thiết bị thất bại");
      }
    }
  };

  const handleClickDelete = () => {
    devicesTableRef.current.handleDeleteMultipleDevices();
  };

  const handleClickConfigure = () => {
    devicesTableRef.current.handleConfigureMultipleDevices();
  };

  // MARK: --- Functions ---
  function handleSelectedTab(tab) {
    setSelectedTab(tab);
  }

  async function getAreas() {
    const res = await areaApi.getAreas();
    const { result, areas } = res.data;
    if (result == "success" && areas) {
      setAreasByUser(areas);
    }
  }

  // MARK: --- Hooks ---
  const allAreas = useSelector((state) => state.area.allAreas);
  useEffect(() => {
    if (
      allAreas &&
      currentUser &&
      currentUser.areaId > 0 &&
      selectedArea?.AreaId != currentUser.areaId
    ) {
      setSelectedArea(
        findAreaById(currentUser.areaId ? currentUser.areaId : -1)
      );
    }
  }, [currentUser, allAreas]);

  useEffect(() => {
    getAreas();
  }, []);

  useEffect(() => {
    console.log(selectedItems);
  }, [selectedItems]);

  useEffect(() => {
    let areaId = selectedArea
      ? selectedArea.AreaId
      : currentUser
      ? currentUser.areaId
      : null;
    if (areaId) {
      setCurrentAreaId(areaId);
    }
  }, [selectedArea, currentUser.areaId]);
  useEffect(() => {
    if (!currentArea) {
      setCurrentArea(
        areasByUser.filter((area) => area.id == currentUser?.areaId).at(0)
      );
    }
  }, [areasByUser]);

  return (
    <>
      <AppPage
        backgroundImage={AppImages.imgBackground4}
        headerTitle="Quản lý thiết bị"
      >
        <NavBar navBarTitle="Quản lý thiết bị"></NavBar>
        <div className="d-flex flex-column flex-column-fluid my-2">
          <AppCard
            title={t("Bảng tin điện tử")}
            buttonText={t("AddDevice")}
            clickAdd={() => setModalAddDeviceShowing(true)}
            additionalHeader={
              <>
                <div className="col-12 col-md-3 max-w-md-300px px-smaller-md-0 pl-md-0">
                  <BaseSearchBar
                    name="searchBar"
                    placeholder={t("Search")}
                    onSubmit={setSearchText}
                    className="w-100"
                  />
                </div>
                <div className="d-flex flex-row align-items-center col-12 col-md-4 max-w-md-300px mt-4 mt-md-0 px-smaller-md-0 pr-md-0">
                  <p
                    className="display-none display-md-block font-weight-bold mr-3 w-md-max-content"
                    style={{ color: "#4A5677" }}
                  >
                    {t("Display")}
                  </p>
                  <SelectArea
                    onSelect={(area) => {
                      setSelectedArea(area);
                    }}
                    rootAreaId={currentUser.areaId}
                    selectedArea={selectedArea}
                    className="flex-grow-1"
                  />
                </div>

                <div className="d-flex flex-row w-100">
                  {numberOfSelectedDevices > 0 ? (
                    <button
                      className="btn btn-light-primary mr-2 d-flex flex-row align-items-center mt-6"
                      onClick={(e) => {
                        e.preventDefault();
                        handleClickConfigure();
                      }}
                    >
                      {selectedTab == tabs[0] ? (
                        <img
                          src={AppIcons.icBulletinBoard}
                          style={{ width: "20px", height: "16px" }}
                          className="mr-2"
                        />
                      ) : (
                        <i className="fas fa-satellite-dish "></i>
                      )}
                      <p
                        className="font-weight-bold text-capitalize m-0"
                        style={{ fontSize: 13 }}
                      >
                        {`${t("Cấu hình")} ${numberOfSelectedDevices}`}
                      </p>
                    </button>
                  ) : null}
                  {selectedTab !== tabs[0] ? null : numberOfSelectedDevices >
                    0 ? (
                    <button
                      className="btn btn-light-danger mr-2 d-flex flex-row align-items-center ml-2 mt-6"
                      onClick={(e) => {
                        e.preventDefault();
                        handleClickDelete();
                      }}
                    >
                      {selectedTab == tabs[0] ? (
                        <img
                          src={AppIcons.icBulletinBoardRed}
                          style={{ width: "20px", height: "16px" }}
                          className="mr-2"
                        />
                      ) : (
                        <i className="fas fa-satellite-dish "></i>
                      )}
                      <p
                        className="font-weight-bold text-capitalize m-0"
                        style={{ fontSize: 13 }}
                      >
                        {`${t("Delete")} ${numberOfSelectedDevices}`}
                      </p>
                    </button>
                  ) : null}
                </div>
              </>
            }
          >
            <div className="w-100 bg-white border-0">
              <div className="d-flex justify-content-between align-items-end border-0">
                <AppTabs
                  tabs={tabs.reverse()}
                  activeTab={selectedTab}
                  handleClick={handleSelectedTab}
                  className="flex-grow-1"
                />
              </div>
            </div>

            <DevicesTable
              getDevicesApi={(params) => {
                let areaId = selectedArea
                  ? selectedArea.AreaId
                  : currentUser.areaId;
                console.log(
                  "area:",
                  selectedArea ? selectedArea.AreaId : currentUser.areaId
                );
                if (areaId) {
                  params.areaId = areaId;
                  switch (selectedTab) {
                    //   case tabs[tabs.length - 1]:
                    //     return areaApi.getDevicesInArea(areaId, params);
                    //     break;
                    case tabs[0]:
                      console.log(params);
                      params.unconfigured = 1;
                      return bulletinBoardApi.getBulletinBoard(areaId, params);
                    default:
                      params.unconfigured = 0;
                      return bulletinBoardApi.getBulletinBoard(areaId, params);
                  }
                }
              }}
              configured={selectedTab == tabs[0] ? false : true}
              deviceType={"bulletin-board"}
              currentAreaId={currentAreaId}
              searchText={searchText}
              tableName={selectedTab}
              setNumberOfSelectedDevices={setNumberOfSelectedDevices}
              ref={devicesTableRef}
              tab={selectedTab}
            />
          </AppCard>
        </div>
      </AppPage>
      <ModalAddDevice
        deviceType="bulletinBoard"
        show={modalAddDeviceShowing}
        onClose={() => setModalAddDeviceShowing(false)}
        rootAreaId={currentUser.areaId}
        name={t("BulletinBoard")}
      />
    </>
  );
}

export default BulletinBoardManagerScreen;
