import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";
import stationApi from "api/stationApi";
import ToastHelper from "general/helpers/ToastHelper";


export const thunkGetStations = createAsyncThunk(
    'station/all',
    async (params, thunkAPI) => {
        const res = await stationApi.getStations();
        return res;
    }
)

export const thunkGetStationsByAreaId = createAsyncThunk(
    'station/get',
    async (params, thunkAPI) => {
        const { areaId } = params;
        const res = await stationApi.getStationsByAreaId(areaId);
        return res;
    }
);

export const thunkUpdateStation = createAsyncThunk(
    'station/update',
    async (params, thunkAPI) => {
        const res = await stationApi.updateStation(params);
        return {res, params};
    }
)

export const thunkCreateStation = createAsyncThunk(
    'station/create',
    async (params, thunkAPI) => {
        const res = await stationApi.createStation(params);
        return res;
    }
)

export const thunkDeleteStation = createAsyncThunk(
    'station/delete',
    async (params, thunkAPI) => {
        const { ids } = params;
        const res = await stationApi.deleteStation(ids);
        return {ids, res};
    }
)


const stationSlice = createSlice({
    name: 'station',
    initialState: {
        isPending: true,
        stations: [],
    },
    reducers: {
        updateStations: (state, action) => {
            state.stations = action.payload;
        },
        check: (state, action) => {
            const index = action.payload;
            state.stations[index] = {...state.stations[index], checked: true};
        },
        uncheck: (state, action) => {
            const index = action.payload;
            state.stations[index] = {...state.stations[index], checked: false};
        },
    },
    extraReducers: {
        // Get All Stations By User
        [thunkGetStations.pending]: (state, action) => {
            state.isPending = true;
        },
        [thunkGetStations.rejected]: (state, action) => {
            state.isPending = false;
        },
        [thunkGetStations.fulfilled]: (state, action) => {
            state.isPending = false;
            const { result, stations } = action.payload.data;
            if (result == 'success') {
                state.stations = stations;
            }
        },

        // Get Stations By Area Id
        [thunkGetStationsByAreaId.pending]: (state, action) => {
            state.isPending = true;
        },
        [thunkGetStationsByAreaId.rejected]: (state, action) => {
            state.isPending = false;
        },
        [thunkGetStationsByAreaId.fulfilled]: (state, action) => {
            state.isPending = false;
            const { result, stations } = action.payload.data;
            if (result == 'success') {
                state.stations = stations;
            }
        },

        // Update Station
        [thunkUpdateStation.rejected]: (state, action) => {
            ToastHelper.showError("Sửa không thành công");
        },
        [thunkUpdateStation.fulfilled]: (state, action) => {
            const { res, params } = action.payload;
            const { id, name } = params;
            if (res.data.result == 'success') {
                ToastHelper.showSuccess("Sửa thành công");
                state.stations.map( (item,index) => {
                    if (item.id == id) {
                        state.stations[index] = {...state.stations[index], name: name};
                    }
                });
            } else ToastHelper.showError("Sửa không thành công");
        },

        // Add Station
        [thunkCreateStation.rejected]: (state, action) => {
            ToastHelper.showError("Thêm không thành công");
        },
        [thunkCreateStation.fulfilled]: (state, action) => {
            const { result, station } = action.payload.data;
            if (result == 'success') {
                ToastHelper.showSuccess("Thêm thành công");
                state.stations.push(station);
            } else ToastHelper.showError("Thêm không thành công");
        },

        // Delete Station
        [thunkGetStationsByAreaId.rejected]: (state, action) => {
            ToastHelper.showError("Xóa không thành công");
        },
        [thunkDeleteStation.fulfilled]: (state, action) => {
            const { ids, res } = action.payload;
            if (res.data.result == 'success') {
                ToastHelper.showSuccess("Xóa thành công");
                if(Array.isArray(ids)){
                    for(let id of ids) state.stations = state.stations.filter(item => item.id != id)
                }
            } else ToastHelper.showError("Xóa không thành công");
        },
    },
})


const { actions, reducer } = stationSlice;

export const { updateStations, check, uncheck } = actions;

export default reducer;
