import { param } from "jquery";
import axiosClient from "./axiosClient";

const historyApi = {
    getLogs: (params) => {
        const url = '/logs/search'
        const {filters, query, page, limit, order} = params
        return axiosClient.post(url, {
            filters: filters,
            order: order,
            query: query,
            page: page,
            limit: limit
        })
    }
}

export default historyApi;