import React, { Component } from "react";
import { connect } from "react-redux";
import classes from "./SelectArea.module.scss";
import SelectAreaItem from "./SelectAreaItem";
import PropTypes from "prop-types";
import { findAreaById, getAreaJSTreeStructure } from "general/utils/Utils";
import store from "libs/storage/store";

const mapStateToProps = (state) => {
  return {
    // area: store.getState().area.allAreas,
  };
};

const mapDispatchToProps = {};

class SelectArea extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownHeight: 0,
      searchString: null,
      rootAreaId: props.rootAreaId,
      dropdownStyle: null,
    };

    this.inputDebounceTimeout = null;
    this.filterArea = this.filterArea.bind(this);
    this.onSelectArea = this.onSelectArea.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.wrapperRef = React.createRef();
    this.divRef = React.createRef();
    // this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.calculateDropdownHeight = this.calculateDropdownHeight.bind(this);
    this.setDropdownHeight = this.setDropdownHeight.bind(this);
  }

  componentDidMount() {
    if (this.props.rootAreaId) {
      this.setState({ rootAreaId: this.props.rootAreaId });
    }
    document.addEventListener("mousedown", this.handleClickOutside);
    window.addEventListener("resize", this.handleResize);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.state.dropdownHeight &&
      this.state.dropdownHeight !== this.calculateDropdownHeight()
    ) {
      this.setState({
        dropdownHeight: this.calculateDropdownHeight(),
        dropdownStyle: this.calculateDropdownStyle(),
      });
    }

    if (prevProps.rootAreaId !== this.props.rootAreaId) {
      if (this.props.rootAreaId) {
        this.setState({ rootAreaId: this.props.rootAreaId });
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    window.removeEventListener("resize", this.handleResize);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({
        dropdownHeight: 0,
      });
    }
  }

  handleResize(event) {
    this.setState({
      dropdownStyle: this.calculateDropdownStyle(),
    });
  }

  toggleDropdown() {
    if (this.props.isDisabled) {
      return;
    }
    if (this.state.dropdownHeight == 0) {
      this.setState({
        dropdownHeight: this.calculateDropdownHeight(), // + padding
        dropdownStyle: this.calculateDropdownStyle(),
      });
      let input = this.wrapperRef.current.querySelector(`input`);
      if (input) {
        input.focus();
      }
    } else {
      this.setState({
        dropdownHeight: 0,
      });
    }
  }

  filterArea(e) {
    if (this.inputDebounceTimeout) {
      window.clearTimeout(this.inputDebounceTimeout);
    }
    this.inputDebounceTimeout = setTimeout(() => {
      this.setState({
        searchString: e.target.value,
      });
    }, 500);
  }

  onSelectArea(areaId) {
    let selectedArea = findAreaById(areaId);
    if (selectedArea) {
      // this.setState({
      //     selectedArea: selectedArea
      // }, () => {
      if (this.props.onSelect) {
        this.props.onSelect(selectedArea);
      }
      this.setState({
        dropdownHeight: 0,
      });
      // });
    }
  }

  setDropdownHeight() {
    this.setState({
      dropdownStyle: this.calculateDropdownHeight(),
      dropdownStyle: this.calculateDropdownStyle(),
    });
  }

  calculateDropdownHeight() {
    return this.divRef?.current.scrollHeight + 12;
  }

  calculateDropdownStyle() {
    if (this.wrapperRef.current) {
      let dropdownWantedWidth = 500;
      let selectContainer = this.wrapperRef.current;
      let leftOffset = $(selectContainer).offset().left,
        widthOffset = selectContainer.offsetWidth,
        rightOffset = $(window).width() - leftOffset - widthOffset;

      let left = null,
        right = null,
        width = dropdownWantedWidth;
      if (rightOffset + widthOffset > dropdownWantedWidth) {
        // dropdown trái -> phải
        left = 0;
      } else if (leftOffset + widthOffset > dropdownWantedWidth) {
        // dropdown phải -> trái
        right = 0;
      } else {
        // dropdown căn theo phía nhiều khoảng trống hơn
        width = rightOffset + widthOffset - 15;

        if (rightOffset > leftOffset) {
          left = 0;
        } else {
          right = 0;
        }
      }
      if (
        (Math.abs(width - widthOffset) * 100) / ((width + widthOffset) / 2) <
          10 ||
        width < widthOffset
      ) {
        width = "100%";
      }
      return {
        left,
        right,
        width,
      };
    }
  }

  render() {
    let areaData = getAreaJSTreeStructure(this.state.rootAreaId, {
      selectedArea: this.props.selectedArea,
      queryString: this.state.searchString,
    });

    return (
      <div
        className={
          classes.container +
          " " +
          this.props.className +
          (this.props.isDisabled ? " q-disabled" : "")
        }
        style={{
          width:
            this.props.width > 0
              ? this.props.width
              : this.props.width == 0
              ? "100%"
              : null,
          ...(this.props.isDisabled && {
            background: "#F3F6F9",
            cursor: "default",
          }),
        }}
        ref={this.wrapperRef}
      >
        <div
          className={`${classes.placeholder_container} ${
            this.state.dropdownHeight
              ? "border-primary"
              : this.props.showBorder
              ? ""
              : "border-0"
          }`}
          onClick={this.toggleDropdown}
        >
          <div
            className={`${
              this.props.selectedArea?.AreaName ? "" : "opacity-40"
            } d-flex align-middle mw-100 pr-2`}
          >
            <span className="col px-0 text-truncate">
              {this.props.selectedArea?.AreaName || "Chọn Khu Vực"}
            </span>
            {this.props.selectedArea && this.props.clearable ? (
              <i
                className="far fa-times ml-2"
                onClick={(e) => {
                  if (this.props.onSelect) {
                    this.props.onSelect(null);
                    e.preventDefault();
                  }
                }}
              />
            ) : (
              ""
            )}
          </div>
          <i className="far fa-angle-down" />
        </div>
        <div
          className={`${classes.dropdown_container} ${
            this.state.dropdownHeight ? "" : "p-0 border-0"
          } ${this.props.dropdownClassName}`}
          style={{
            maxHeight: this.state.dropdownHeight,
            ...this.state.dropdownStyle,
          }}
          ref={this.divRef}
        >
          {this.props.showSearchBar && (
            <input className="form-control" onInput={this.filterArea} />
          )}
          <div className={classes.select_tree_container}>
            {areaData ? (
              Array.isArray(areaData) ? (
                areaData.map((area) => {
                  return this.props.exceptIds.includes(area.AreaId) ? (
                    ""
                  ) : (
                    <SelectAreaItem
                      area={area}
                      key={area.AreaId}
                      onSelect={this.onSelectArea}
                      exceptIds={this.props.exceptIds}
                      setDropdownHeight={this.setDropdownHeight}
                    />
                  );
                })
              ) : this.props.exceptIds.includes(areaData.AreaId) ? (
                ""
              ) : (
                <SelectAreaItem
                  area={areaData}
                  key={areaData.AreaId}
                  onSelect={this.onSelectArea}
                  exceptIds={this.props.exceptIds}
                  setDropdownHeight={this.setDropdownHeight}
                />
              )
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}

SelectArea.propTypes = {
  onSelect: PropTypes.func.isRequired, // sự kiện khi chọn khu vực (tham số: khu vực được chọn (object))
  selectedArea: PropTypes.object, // khu vực được chọn truyền từ component cha (mặc định null), cập nhật giá trị trong sự kiện onSelect trong component cha
  dropdownClassName: PropTypes.string,
  exceptIds: PropTypes.array,
  showBorder: PropTypes.bool,
  width: PropTypes.number,
  showSearchBar: PropTypes.bool,
};

SelectArea.defaultProps = {
  exceptIds: [],
  showBorder: true,
  width: -1,
  showSearchBar: true,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectArea);
