import React, {Component} from 'react';
import classes from "./SelectArea.module.scss";
import PropTypes from "prop-types";

class SelectAreaItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            expand: this.props.area?.state?.opened || false,
        }

        this.toggleChild = this.toggleChild.bind(this);
        this.onSelect = this.onSelect.bind(this);
    }

    toggleChild() {
        this.props.setDropdownHeight();
        this.setState({
            expand: !this.state.expand
        })
    }

    onSelect(areaId) {
        if (areaId && Number(areaId)) {
            this.props.onSelect(areaId);
        } else {
            this.props.onSelect(this.props.area.AreaId);
        }
    }

    // static getDerivedStateFromProps(props, state) {
    //     debugger;
    //     if (props.area?.state?.opened !== state.expand) {
    //         return {
    //             expand: props.area?.state?.opened || false,
    //         }
    //     }
    //     return null;
    // }

    render() {
        let childArea = Array.isArray(this.props.area?.children) ? this.props.area.children.filter(area => !this.props.exceptIds.includes(area.AreaId)) : [];
        return (
            <div className={`${classes.tree_item_container}`}>
                <div className={classes.tree_item}>
                    {childArea.length ? (this.state.expand ?
                        <i className={classes.toggle_expand + " fal fa-minus-square mr-2"}
                           onClick={this.toggleChild}/> :
                        <i className={classes.toggle_expand + " fal fa-plus-square mr-2"}
                           onClick={this.toggleChild}/>) : <i className="fal fa-plus-square mr-2 invisible"/>
                    }
                    <div className="d-flex align-items-center cursor-pointer" onClick={this.onSelect}>
                        <i className="fal fa-folder-open text-primary mr-2"/>
                        <div className="flex-grow-1">{this.props.area.text}</div>
                    </div>

                </div>
                <div className={this.state.expand ? "" : "d-none"}>
                    {(childArea.length && this.state.expand) ? (childArea.map(child => {
                        return <SelectAreaItem
                            area={child}
                            key={child.AreaId}
                            onSelect={this.onSelect}
                            exceptIds={this.props.exceptIds}
                            setDropdownHeight={this.props.setDropdownHeight}
                        />
                    })) : ""}
                </div>
            </div>
        )
    }
}


SelectAreaItem.propTypes = {
    onSelect: PropTypes.func.isRequired,
    setDropdownHeight: PropTypes.func.isRequired,
    area: PropTypes.object,
    exceptIds: PropTypes.array,
}

SelectAreaItem.defaultProps = {
    exceptIds: [],
}

export default SelectAreaItem;
