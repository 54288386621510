import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Form, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import "./style.scss";
import BaseTextField from "general/custom-fields/BaseTextField";
import { addArea, updateArea } from "pages/AreaManager/areaSlice";
import areaApi from "api/areaApi";
import ToastHelper from "general/helpers/ToastHelper";

ModalCreateArea.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  editableArea: PropTypes.object,
};

ModalCreateArea.defaultProps = {
  show: false,
  onClose: null,
  editableArea: null,
};

function ModalCreateArea(props) {
  // MARK: --- Params ---
  const { show, onClose, editableArea } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { currentArea } = useSelector((state) => state.area);

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      latitude: "",
      longitude: "",
    },
    onSubmit: (values) => {
      if(editableArea)
          handleUpdateArea({
              id: editableArea.id,
              name: values.name,
              latitude: values.latitude,
              longitude: values.longitude
          })
      else handleAddArea({
          parentId: currentArea.id,
          name: values.name,
          description: values.description,
          latitude: values.latitude,
          longitude: values.longitude
      })
      handleClose();
    },
    validationSchema: yup.object({
      name: yup.string().required(t("Required")),
      latitude: yup
        .number()
        .typeError("Tọa độ không bao gồm chữ")
        .min(-90, "Giá trị phải lớn hơn -90")
        .max(+90, "Giá trị phải nhỏ hơn +90"),
      longitude: yup
        .number()
        .typeError("Tọa độ không bao gồm chữ")
        .min(-180, "Giá trị phải lớn hơn -180")
        .max(+180, "Giá trị phải nhỏ hơn +180"),
    }),
  });

  // MARK: --- Functions ---
  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  async function handleAddArea(params) {
    const res = await areaApi.createArea(params);
    const { result, area } = res.data;

    if (result == "success") {
      ToastHelper.showSuccess(t("CreatedAreaSuccessfully"));
      dispatch(addArea(area));
    }
  }

  async function handleUpdateArea(params) {
    const res = await areaApi.updateArea(params);
    const { result } = res.data;

    if (result == "success") {
      ToastHelper.showSuccess(t("UpdatedAreaSuccessfully"));
      dispatch(updateArea(params));
    }
  }
  // MARK: --- Hooks ---

  useEffect(() => {
    if (editableArea) {
      editableArea?.name &&
        formik.getFieldHelpers("name").setValue(editableArea?.name);
      editableArea?.latitude &&
        formik.getFieldHelpers("latitude").setValue(editableArea?.latitude);
      editableArea?.longitude &&
        formik.getFieldHelpers("longitude").setValue(editableArea?.longitude);
    }
  }, [editableArea]);

  return (
    <div>
      <Modal
        className="font-weight-bold"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={() => {
          handleClose();
        }}
      >
        <Modal.Header className="d-flex justify-content-center">
          <Modal.Title>
            <p className="Bold_13">{t("Area")}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="bg-light">
          <BaseTextField
            className="FieldName Normal_12 pb-5"
            name="name"
            label={t("AreaName")}
            placeholder={t("AreaName")}
            fieldProps={formik.getFieldProps("name")}
            fieldHelpers={formik.getFieldHelpers("name")}
            fieldMeta={formik.getFieldMeta("name")}
          />

          {!editableArea && (
            <>
              <BaseTextField
                className="FieldName Normal_12 pb-5"
                name="description"
                label={t("Description")}
                placeholder={t("Description")}
                fieldProps={formik.getFieldProps("description")}
                fieldHelpers={formik.getFieldHelpers("description")}
                fieldMeta={formik.getFieldMeta("description")}
              />
            </>
          )}

          <BaseTextField
            className="FieldName Normal_12 pb-5"
            name="longitude"
            label={t("Longitude")}
            placeholder={t("Longitude")}
            fieldProps={formik.getFieldProps("longitude")}
            fieldHelpers={formik.getFieldHelpers("longitude")}
            fieldMeta={formik.getFieldMeta("longitude")}
          />

          <BaseTextField
            className="FieldName Normal_12 pb-5"
            name="latitude"
            label={t("Latitude")}
            placeholder={t("Latitude")}
            fieldProps={formik.getFieldProps("latitude")}
            fieldHelpers={formik.getFieldHelpers("latitude")}
            fieldMeta={formik.getFieldMeta("latitude")}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <div className="row w-100">
            <div className="col-6">
              <a
                className="w-100 btn btn-light font-weight-bold"
                onClick={() => handleClose()}
              >
                {t("Cancel")}
              </a>
            </div>

            <div className="col-6">
              <a
                className="w-100 btn btn-primary font-weight-bold"
                onClick={() => {
                  formik.handleSubmit();
                }}
              >
                {t("Save")}
              </a>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ModalCreateArea;
