import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";

import { thunkCreateStation } from "pages/RadioStationManager/stationSlice";

import SelectArea from "components/SelectArea/SelectArea";
import BaseTextField from "general/custom-fields/BaseTextField";

import "./style.scss";
import { useHistory } from "react-router-dom";
import deviceApi from "api/deviceApi";
import ToastHelper from "general/helpers/ToastHelper";
import { current } from "@reduxjs/toolkit";
import { thunkGetAreas } from "pages/AreaManager/areaSlice";
import SelectRadioStation from "components/SelectRadioStation/SelectRadioStation";
import bulletinBoardApi from "api/bulletinBoardApi";
import Utils, { findAreaById } from "general/utils/Utils";

ModalAddDevice.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  rootAreaId: PropTypes.number,
  name: PropTypes.string,
};

ModalAddDevice.defaultProps = {
  show: false,
  onClose: null,
};

function ModalAddDevice(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.auth.current);
  const [selectedArea, setSelectedArea] = useState(currentUser?.areaId ?? null);
  const [selectedRadioStation, setSelectedRadioStation] = useState(null);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const allAreas = useSelector((state) => state.area.allAreas);
  var origin = window.location.origin;
  // MARK: --- Params ---
  const { show, onClose, name, deviceType } = props;

  useEffect(() => {
    dispatch(thunkGetAreas({}));
  }, []);

  useEffect(() => {
    setSelectedArea(findAreaById(currentUser.areaId ? currentUser.areaId : -1));
  }, [currentUser, loading]);

  useEffect(() => {
    setSelectedRadioStation({});
  }, [selectedArea]);

  const saveChangeDevice = async (id, areaId) => {
    try {
      if (Utils.isObjectEmpty(selectedArea)) {
        ToastHelper.showError("Chưa chọn khu vực");
      }
      if (Utils.isObjectEmpty(selectedRadioStation)) {
        ToastHelper.showError("Chưa chọn đài truyền thanh");
      } else {
        setLoading(true);
        let res = await deviceApi.getDeviceById(id);
        if (res?.data?.status == 0) {
          if (res.data.speaker.radioStationId !== null) {
            ToastHelper.showError("Thiết bị đã được cấu hình");
            setLoading(false);
            handleClose();
          } else {
            deviceApi
              .updateDevice({
                ids: [id],
                areaId: selectedArea.AreaId,
                radioStationId: selectedRadioStation.id,
                // name: inputNameValue,
              })
              .then((data) => {
                setLoading(false);
                handleClose();
                console.log(data);
                if (data.data.status == 0) {
                  ToastHelper.showSuccess(`Đã thêm thiết bị ${id}`);
                  // onSubmited();
                }
              });
          }
        } else {
          setLoading(false);
          handleClose();
          ToastHelper.showError("Thiết bị không tồn tại");
        }
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const saveChangeBulletinBoard = async (id, areaId) => {
    debugger;
    try {
      const res = await bulletinBoardApi.updateBulletinBoard({
        id: id,
        areaId: areaId,
      });
      console.log(res);
      if (res.data.status == 0) {
        ToastHelper.showSuccess(`Đã thêm thiết bị ${id}`);
      } else {
        ToastHelper.showError("Thiết bị không tồn tại");
      }
    } catch (err) {
      // setSubmitDisable(false);
      console.log(err);
    }
  };

  // MARK: --- Formik ---
  const formik = useFormik({
    initialValues: {
      id: "",
      areaId: currentUser?.areaId ?? currentUser?.area?.id,
    },
    validationSchema: yup.object({
      id: yup.string().required(t("Required")),
      areaId: yup.number().required(t("Required")),
    }),
    onSubmit: (values) => {
      setSelectedArea(null);
      if (name == t("RadioDevice")) {
        saveChangeDevice(values.id, values.areaId);
      } else {
        saveChangeBulletinBoard(values.id, values.areaId);
      }
      formik.handleReset();
    },
  });

  // MARK: --- Functions ---
  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  return (
    <Modal
      className="AddDevice font-weight-bold"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={() => {
        handleClose();
      }}
    >
      <Modal.Header className="d-flex justify-content-center">
        <Modal.Title className="Bold_13">
          {t("Thêm")} {name.toLowerCase()}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-light">
        <BaseTextField
          className="StationName Normal_12"
          name="name"
          label={t("ID thiết bị")}
          placeholder={t("ID thiết bị")}
          fieldProps={formik.getFieldProps("id")}
          fieldHelpers={formik.getFieldHelpers("id")}
          fieldMeta={formik.getFieldMeta("id")}
        />

        <div
          className="mt-3 mb-1"
          style={{
            fontSize: 12,
            fontWeight: 500,
            color: "#9EA5BD",
          }}
        >
          {t("Area")}
        </div>
        <SelectArea
          className="bg-white rounded"
          width={0}
          onSelect={(area) => {
            if (selectedArea) {
              if (selectedArea.AreaId != area.AreaId) {
                console.log("Selected area", area);
                setSelectedArea(area);
              }
            } else {
              setSelectedArea(area);
            }
          }}
          rootAreaId={currentUser.areaId}
          selectedArea={selectedArea}
        />
        {deviceType == "bulletinBoard" ? null : (
          <>
            <div
              className="mt-3 mb-1"
              style={{
                fontSize: 12,
                fontWeight: 500,
                color: "#9EA5BD",
              }}
            >
              Đài truyền thanh
            </div>
            <SelectRadioStation
              className="bg-white rounded"
              onSelect={(radioStation) => {
                setSelectedRadioStation(radioStation);
              }}
              areaId={selectedArea ? selectedArea?.AreaId : currentUser?.areaId}
              selectedRadioStation={selectedRadioStation}
              // deviceStationId={device.radioStationId}
              noBorder={false}
            />{" "}
          </>
        )}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center">
        <div className="row w-100">
          <div className="col-6">
            <a
              className="w-100 btn btn-light  font-weight-bold"
              onClick={() => handleClose()}
            >
              {t("Cancel")}
            </a>
          </div>

          <div className="col-6">
            <a
              className="w-100 btn btn-primary font-weight-bold"
              onClick={() => {
                loading == false ? formik.handleSubmit() : null;
              }}
            >
              {loading === false ? null : (
                <span className="spinner-border spinner-border-sm mr-2 mt-1"></span>
              )}
              {t("Save")}
            </a>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

export default ModalAddDevice;
