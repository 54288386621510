import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Loading from "components/Loading";
import SearchBarNoFormik from "../SearchBarNoFormik";

import "./index.scss";
import { AppIcons } from "general/constants/AppResource";

AccountCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  blueButtonText: PropTypes.string,
  whiteButtonText: PropTypes.string,
  clickAdd: PropTypes.func,
  headerRight: PropTypes.element,
  additionalHeader: PropTypes.element,
  isLoading: PropTypes.bool,
  loading : PropTypes.bool,
};

AccountCard.defaultProps = {
  className: "",
  title: "",
  clickAdd: null,
  isLoading: false,
  loading : false ,
};

/**
 * - className (string)
 * - title (string)
 * - headerRight (element) : component after search and button
 * - buttonText (string) default: 'Add'. If empty wont show button
 * - additionalHeader (element): component below header
 *
 */
function AccountCard(props) {
  const {
    className,
    title,
    blueButtonText,
    whiteButtonText,
    clickSave,
    isLoading,
    clickDelete,
    loading,
  } = props;
  const { t } = useTranslation();

  function onClickSave() {
    if (clickSave) clickSave();
  }

  function onClickDelete() {
    if (clickDelete) clickDelete();
  }

  return (
    <div
      className={`${className} d-flex flex-column shadow-sm round-top w-100 bg-white rounded`}
      style={{ zIndex: "5" }}
    >
      {/* CARD HEADER  */}
      <div className="CardHeader d-flex flex-row flex-wrap justify-content-between align-items-center border-bottom px-6">
        {/* TITLE  */}
        <p className="CardTitle Bold_14 text-uppercase pt-5 pb-2 m-0 mr-3">
          {title}
        </p>

        {/* RIGHT SIDE  */}
        <div className="CardToolkit d-flex flex-row flex-wrap align-items-center pt-4 pb-3">
          {props.headerRight}

          {blueButtonText?.length > 0 && (
            <button
              className="CardButtonBlue d-flex flex-row align-items-center justify-content-center border"
              onClick={onClickSave}
              disabled = {loading}
              type = "submit button"
            >
              {loading === false ? null : (
                <span className="spinner-border spinner-border-sm mt-1"></span>
              )}
              <p className="Bold_13 text-white m-0">{t(blueButtonText)}</p>
            </button>
          )}
          {whiteButtonText?.length > 0 && (
            <button
              className="CardButtonWhite d-flex flex-row align-items-center ml-2 border"
              onClick={onClickDelete}
            >
              <img src={AppIcons.icTrash} />
              <p className="Bold_13 m-0">{t(whiteButtonText)}</p>
            </button>
          )}
        </div>
      </div>

      {isLoading ? (
        <div className="d-flex align-items-center justify-content-center p-10">
          <Loading message={`${t("Loading")}...`} />
        </div>
      ) : (
        <>
          {/* ADDITIONAL HEADER  */}

          <div className="w-100">
            {props.children}
          </div>
        </>
      )}
    </div>
  );
}

export default AccountCard;
