import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Modal } from "react-bootstrap";
import { AppImages } from "general/constants/AppResource";
import FormikControl from "pages/Login/components/inputs/FormikControl";
import { ErrorMessage, Field, Form, Formik, useFormik } from "formik";
import PassWordInput from "../PasswordInput";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import authApi from "api/authApi";
import Utils from "general/utils/Utils";
import PreferenceKeys from "general/constants/PreferenceKeys";
import { useHistory } from "react-router-dom";
import ToastHelper from "general/helpers/ToastHelper";
import "./styles.scss";
import ModalOTP from "../ModalOTP";
import { unwrapResult } from "@reduxjs/toolkit";

ModalChangePassword.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

ModalChangePassword.defaultProps = {
  show: false,
  onClose: null,
};
let hiddenEmail;
function ModalChangePassword(props) {
  // MARK: --- Params ---
  // const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const currentUser = useSelector((state) => state.auth.current);
  const { show, onClose, reason } = props;
  const [loading, setLoading] = useState(false);
  const [modalOTP, setModalOTP] = useState(false);
  const [oldPassword, setOldPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);

  const passwordMaxLength = process.env.REACT_APP_PASSWORD_MAX_LENGTH;
  const passwordMinLength = process.env.REACT_APP_PASSWORD_MIN_LENGTH;
  const passwordHaveCapitalLetter =
    process.env.REACT_APP_PASSWORD_CAPITAL_LETTER;
  const passwordHaveNonCapitalLetter =
    process.env.REACT_APP_PASSWORD_NON_CAPITAL_LETTER;
  const passwordHaveNumber = process.env.REACT_APP_PASSWORD_NUMBER_LETTER;
  const [passwordRequirement, setPasswordRequirement] = useState(null);
  let initialPasswordErrorText =
    "Mật khẩu gồm ít nhất " + passwordMinLength + " kí tự";
  const [passwordRequirementErrorText, setPasswordRequirementErrorText] =
    useState(initialPasswordErrorText);
  let regExp = new RegExp(/^/);

  useEffect(() => {
    if (passwordHaveCapitalLetter == "true") {
      regExp = new RegExp(regExp.source + /(?=.*[A-Z])/.source);
      initialPasswordErrorText =
        initialPasswordErrorText + ", 1 kí tự viết hoa";
      setPasswordRequirementErrorText(initialPasswordErrorText);
    }
    if (passwordHaveNonCapitalLetter == "true") {
      regExp = new RegExp(regExp.source + /(?=.*[a-z])/.source);
      initialPasswordErrorText =
        initialPasswordErrorText + ", 1 kí tự viết thường";
      setPasswordRequirementErrorText(initialPasswordErrorText);
    }
    if (passwordHaveNumber == "true") {
      regExp = new RegExp(regExp.source + /(?=.*[0-9])/.source);
      initialPasswordErrorText = initialPasswordErrorText + ", 1 chữ số";
      setPasswordRequirementErrorText(initialPasswordErrorText);
    }
    setPasswordRequirement(regExp);
  }, []);

  useEffect(() => {
    console.log(document.getElementById("123"));
    if (document.getElementsByName("oldPassword")[0]) {
      document.getElementsByName("oldPassword")[0].focus();
    }
  }, []);

  useEffect(() => {
    if (currentUser.email) {
      hiddenEmail = currentUser.email.slice(0, 4);
      for (let i = 0; i < currentUser.email.length - 4; i++) {
        hiddenEmail += "*";
      }
    }
  }, [currentUser.email]);

  function handleClose() {
    if (onClose) {
      onClose();
    }
  }

  const initialValues = {
    userId: currentUser.id,
    oldPassword: "",
    newPassword: "",
    passwordConfirmation: "",
  };

  const validationSchema = Yup.object({
    oldPassword: Yup.string().required(t("enterPass")),
    newPassword: Yup.string()
      .min(passwordMinLength, passwordRequirementErrorText)
      .max(passwordMaxLength, passwordRequirementErrorText)
      .required(t("enterPass"))
      .matches(passwordRequirement, passwordRequirementErrorText)
      .matches(/^(?!.*[\s])/, "Có khoảng trắng trong mật khẩu"),
    passwordConfirmation: Yup.string().oneOf(
      [Yup.ref("newPassword"), null],
      t("PasswordMustMatch")
    ),
  });

  const onSubmitModalOTP = async (values) => {
    try {
      // setLoading(true);
      let res = await authApi.updateCurrentUserPassword(values);

      if (res) {
        let data = res.data;
        // setLoading(false);
        if (data.result === "success") {
          handleClose();
          ToastHelper.showSuccess(t("ChangePasswordSuccess"));
          localStorage.removeItem(PreferenceKeys.refreshToken);
          localStorage.removeItem(PreferenceKeys.accessToken);
          history.push("/dashboard");
        }
      }
      return res;
    } catch (error) {
      // setLoading(false);
    }
  };

  const onSubmit = async (values, props) => {
    // Người dùng bật xác thức 2 lớp
    if (currentUser.twoFactor) {
      setNewPassword(values.newPassword);
      setOldPassword(values.oldPassword);
      setLoading(true);
      try {
        let res = await authApi.getOTP();
        if (res) {
          setLoading(false);
          if (res?.data?.status == 0) {
            setModalOTP(true);
            ToastHelper.showSuccess(
              "Chúng tôi đã gửi mã OTP đến " + hiddenEmail
            );
          }
        }
      } catch (err) {
        setLoading(false);
      }
    }

    // người dùng không bật xác thực 2 lớp
    else {
      try {
        console.log(values);
        setLoading(true);
        let res = await authApi.updateCurrentUserPassword(values);
        if (res) {
          console.log(res);
          let data = res.data;
          setLoading(false);
          console.log(data);
          if (data.result === "success") {
            handleClose();
            ToastHelper.showSuccess(t("ChangePasswordSuccess"));
            localStorage.removeItem(PreferenceKeys.refreshToken);
            localStorage.removeItem(PreferenceKeys.accessToken);
            history.push("/dashboard");
          }
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };

  return (
    <Modal
      className=""
      // size="sm"
      show={show}
      dialogClassName="modalChangePassword"
      onHide={() => {
        handleClose();
      }}
      centered
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {(formik) => {
          return (
            <Form>
              <Modal.Header className="d-flex justify-content-center py-4">
                <Modal.Title className="">
                  <span className="">{t("ChangePassword")}</span>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body
                className="bg-light pt-4 modalChangePasswordBody"
                id=""
              >
                <PassWordInput
                  label={
                    <p className="font-weight-normal font-size-sm line-height-sm mb-0">
                      {t("password")}
                    </p>
                  }
                  placeholder={t("EnterCurrentPassword")}
                  name="oldPassword"
                ></PassWordInput>
                <PassWordInput
                  label={
                    <p className="font-weight-normal font-size-sm line-height-sm mb-0">
                      {t("NewPassword")}
                    </p>
                  }
                  placeholder={t("EnterNewPassword")}
                  name="newPassword"
                ></PassWordInput>
                <PassWordInput
                  label={
                    <p className="font-weight-normal font-size-sm line-height-sm mb-0">
                      {t("ReenterNewPassword")}
                    </p>
                  }
                  placeholder={t("EnterNewPassword")}
                  name="passwordConfirmation"
                ></PassWordInput>
              </Modal.Body>
              <Modal.Footer className="d-flex justify-content-center">
                <div className="row w-100">
                  <div className="col-6">
                    <a
                      className="w-100 btn btn-light  font-weight-bold"
                      onClick={() => handleClose()}
                    >
                      {t("Cancel")}
                    </a>
                  </div>
                  <div className="col-6">
                    <button
                      className="w-100 btn font-weight-bold text-white d-flex justify-content-center"
                      type="submit button"
                      style={{ backgroundColor: "#2AB448" }}
                      disabled={!formik.isValid || loading}
                    >
                      {loading === false ? null : (
                        <span className="spinner-border spinner-border-sm mr-2 mt-1"></span>
                      )}
                      <p className="font-weight-bold text-white mb-0">
                        {t("Save")}
                      </p>
                    </button>
                  </div>
                </div>
              </Modal.Footer>
            </Form>
          );
        }}
      </Formik>
      <ModalOTP
        show={modalOTP}
        onClose={() => setModalOTP(false)}
        onSubmitModalOTP={onSubmitModalOTP}
        oldPassword={oldPassword}
        newPassword={newPassword}
      />
    </Modal>
  );
}

export default ModalChangePassword;
