import { AppIcons } from "general/constants/AppResource";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

let KTLayoutHeaderMobile = require("assets/plugins/header/ktheader-mobile");
let KTLayoutHeaderTopbar = require("assets/plugins/header/ktheader-topbar");

HeaderMobile.propTypes = {};

function HeaderMobile(props) {
  // MARK: --- Params ---
  const currentUser = useSelector((state) => state.auth.current);
  const { Avatar, Email, Username, Fullname } = currentUser;

  useEffect(() => {
    // Init Mobile Header
    if (
      typeof KTLayoutHeaderMobile !== "undefined" &&
      !window.KTLayoutHeaderMobileInitialed
    ) {
      window.KTLayoutHeaderMobileInitialed = true;
      KTLayoutHeaderMobile.init("kt_header_mobile");
    }

    // Init Header Topbar For Mobile Mode
    if (
      typeof KTLayoutHeaderTopbar !== "undefined" &&
      !window.KTLayoutHeaderTopbarInitialed
    ) {
      window.KTLayoutHeaderTopbarInitialed = true;
      KTLayoutHeaderTopbar.init("kt_header_mobile_topbar_toggle");
    }
  }, []);

  return (
    <div
      className="header-mobile"
      id="kt_header_mobile"
      style={{ zIndex: "40" }}
    >
      {/* begin::Logo */}
      <Link className="aside-logo" to="/">
        <img
          className="max-h-30px"
          src={Avatar ?? AppIcons.icLogo}
          alt="Logo"
          style={{ objectFit: "cover" }}
        />
      </Link>
      {/* end::Logo */}

      {/* begin::Toolbar */}
      <div className="d-flex align-items-center">
        <button
          className="btn btn-icon btn-icon-white btn-hover-icon-white"
          id="kt_aside_mobile_toggle"
        >
          <span className="svg-icon svg-icon-xxl">
            <i className="fad fa-bars"></i>
          </span>
        </button>

        {/* <button className="btn btn-icon btn-icon-white btn-hover-icon-white ml-1" id="kt_header_mobile_topbar_toggle">
                    <span className="svg-icon svg-icon-xl">
                        <i className="fad fa-user"></i>
                    </span>
                </button> */}
      </div>
      {/* end::Toolbar */}
    </div>
  );
}

export default HeaderMobile;
