import axiosClient from "./axiosClient";

const areaApi = {
  getAreas: () => {
    const url = `/areas`;
    return axiosClient.get(url);
  },
  // Lấy các khu vực con của 1 khu vực
  getAreasInAreaId: (areaId) => {
    const url = `/areas/${areaId}`;
    return axiosClient.get(url);
  },

  getDevicesInArea: (areaId, params) => {
    console.log("[AreaApi] getdevice in Area", areaId);
    const url = `/areas/${areaId}/devices`;
    return axiosClient.get(url, { params });
  },

  createArea: (params) => {
    const { parentId, name, description, latitude, longitude } = params;
    const url = `/areas/create`;
    return axiosClient.put(url, {
      parentId: parentId,
      name: name,
      description: description,
      latitude: latitude,
      longitude: longitude,
    });
  },

  updateArea: (params) => {
    const { id, name, latitude, longitude } = params;
    const url = `/areas/update`;
    return axiosClient.post(url, {
      id: id,
      name: name,
      latitude: latitude,
      longitude: longitude,
    });
  },

  deleteArea: (id) => {
    const url = `/areas/delete`;
    return axiosClient.delete(url, { data: { ids: `[${id.toString()}]` } });
  },
};

export default areaApi;
