import axiosClient from "./axiosClient";

const providerApi = {
    getProviders: () => {
        const url = `/providers`;
        return axiosClient.get(url);
    },

    getAccountOfProviders: (id) => {
        const url = `/providers/${id}/accounts`;
        return axiosClient.get(url);
    },

    getDevicesOfAccount: (accountId , params) => {
        const url = `/providers/accounts/${accountId}/devices`;
        return axiosClient.get(url, {params});
    },

    addProvider: (params) => {
        const { name, apiKey, contact, address } = params
        const url = `/providers/create`;
        return axiosClient.put(url, {
            name: name,
            apiKey: apiKey,
            contact: contact,
            address: address
        });
    },

    deleteProvider: (id) => {
        const url = `/providers/delete`;
        return axiosClient.delete(url, {data: {id: id}});
    },

    modifyProvider: (params) => {
        const { id, name, apiKey, contact, address } = params
        const url = `/providers/update`;
        return axiosClient.post(url, {
            id: id,
            name: name,
            apiKey: apiKey,
            contact: contact,
            address: address
        });
    },

    addAccount: (params) => {
        const { providerId, serverUrl, account, password, radioStationId, areaId } = params
        const url = `/providers/accounts/add`;
        return axiosClient.put(url, {
            serverUrl: serverUrl,
            account: account,
            password: password,
            radioStationId: radioStationId,
            providerId: providerId,
            areaId: areaId
        });
    },

    deleteAccount: (id) => {
        const url = `/providers/accounts/delete`;
        return axiosClient.delete(url, {data: {ids: `[${id.toString()}]`}});
    },

    modifyAccount: (params) => {
        const { id, serverUrl, account, password } = params
        const url = `/providers/accounts/update`;
        return axiosClient.post(url, {
            id: id,
            serverUrl: serverUrl,
            account: account,
            password: password,
        });
    },
}

export default providerApi;
