import React from "react";
import "./style.scss";
import { AppIcons } from "../../general/constants/AppResource";

function Connection(props) {
  const { id, type } = props;

  function renderType(type) {
    switch (type.toUpperCase()) {
      case "OFFLINE":
        return (
          <div id={id} className="d-flex">
            <img id={id} src={AppIcons.icOffline} className="pr-3" />
            <p id={id} className="Regular_13 text-emphasis mt-1">Offline</p>
          </div>
        );
      case "ETHERNET":
        return (
          <div id={id} className="d-flex ">
            <i
              id={id}
              className="fas fa-network-wired text-primary pr-3 pt-1"
              style={{ fontSize: 14 }}
            />
            <p id={id} className="Regular_13 text-emphasis mt-1">Ethernet</p>
          </div>
        );
      case "WIFI":
        return (
          <div id={id} className="d-flex">
            <i
              id={id}
              className="fas fa-wifi text-primary pr-3 pt-1"
              style={{ fontSize: 14 }}
            />
            <p id={id} className="Regular_13 text-emphasis mt-1">WiFi</p>
          </div>
        );
      case "3G":
        return (
          <div id={id} className="d-flex ">
            <img id={id} src={AppIcons.ic3G} className="pr-3" />
            <p id={id} className="Regular_13 text-emphasis mt-1">3G</p>
          </div>
        );
      case "4G":
        return (
          <div id={id} className="d-flex justify-content-center">
            <img id={id} src={AppIcons.ic4G} className="pr-3" />
            <p id={id} className="Regular_13 text-emphasis mt-1">4G</p>
          </div>
        );
      default:
        return <div></div>;
    }
  }

  return renderType(type);
}

export default Connection;
